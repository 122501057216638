import {usePageSize} from "@/App";
import {ImageUrls} from "@/asset/icon";
import {ProjectPlan, ProjectPurpose} from "@/views/product/config";
import {Box, Flex, Img, Text} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {useLocation} from "react-router";
import "./style.css";
import {useTranslation} from "react-i18next";
import p_001_en from '../../asset/image/english/p_001_en.png'
import p_002_en from '../../asset/image/english/p_002_en.png'
import p_003_en from '../../asset/image/english/p_003_en.png'
import p_004_en from '../../asset/image/english/p_004_en.png'
import i18n from "../../react-i18next-config";

const Project: React.FC = () => {
  const [, setKey] = useState<string>("project");
  const {search} = useLocation();

  useEffect(() => {
    if (search) {
      const t = search?.split("=")[1];
      setKey(t);
    }
  }, [search]);

  const {t} = useTranslation()
  const lang = i18n.language

  const pageSize = usePageSize();
  const small = pageSize === "xs";
  return (
    <Box className="Product">
      <Box position="relative">
        {!small && <Img src={ImageUrls.icon20} position="absolute" left="0" bottom="-15%" h="94%" zIndex="-1"/>}

        <Box textAlign="center" className="wrap" pb="4rem" pt="4rem">
          <Box mb="3rem">
            <Text color="#414040" fontSize="2rem" fontWeight="600">
              {t('projects.title')}
            </Text>
          </Box>
          <Box fontSize="1.125rem" color="#666" lineHeight="3rem" mb="2rem">
            <Text>
              {t('projects.desc')}
            </Text>
          </Box>
        </Box>
      </Box>

      <Box
        position="relative"
        pt="4rem"
        pb="4rem"
        backgroundImage={ImageUrls.p_md_bg}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      >
        <Text color="#fff" textAlign="center" pb="2.5rem 0 1rem" fontSize="2rem" fontWeight="600">
          {t('projects.objective.title')}
        </Text>

        <Box className="wrap">
          <Flex justify="space-between" flexWrap="wrap">
            {ProjectPurpose.map((item) => {
              return (
                <Flex w={small ? "100%" : "32%"} key={item.index}>
                  <Box p="3rem  0.5rem 1.5rem" m={small ? "1.25rem 0" : "1.25rem"} w="100%">
                    <Text mb="1.5rem" fontSize="3rem" color={item.color} lineHeight="1.75rem" letterSpacing="1.5px">
                      {item.index}
                    </Text>

                    {item.detail.map((text, index) => (
                      <Text fontSize="1.125rem" color="#fff" lineHeight="1.75rem" letterSpacing="1.5px" key={index}>
                        {t(text)}
                      </Text>
                    ))}
                  </Box>
                </Flex>
              );
            })}
          </Flex>
        </Box>
      </Box>

      <Box position="relative" pt="4rem" pb="4rem">
        <Box textAlign="center" pb="3rem">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('projects.unit')}
          </Text>
        </Box>

        <Box className="wrap">
          <Flex justify="space-between" position="relative" flexWrap="wrap">
            {
              lang === 'en-US'
                ?
                <>
                  <Img src={p_001_en} w={small ? "100%" : "47%"} mb="1.5rem"/>
                  <Img src={p_002_en} w={small ? "100%" : "47%"} mb="1.5rem"/>
                </>
                :
                <>
                  <Img src={ImageUrls.p_001} w={small ? "100%" : "47%"} mb="1.5rem"/>
                  <Img src={ImageUrls.p_002} w={small ? "100%" : "47%"} mb="1.5rem"/>
                </>
            }
          </Flex>
        </Box>

        <Box textAlign="center" p="4rem 0 1.5rem">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('projects.committee')}
          </Text>
        </Box>

        <Box className="wrap">
          <Flex justify="space-between" position="relative" flexWrap="wrap">
            <Box w={small ? "100%" : "47%"} mb="1.5rem">
              <Text fontSize="1.5rem" color="#414040" textAlign="center" fontWeight="500" mb="1.5rem">
                {t('projects.clinical')}
              </Text>
              {
                lang === 'en-US'
                  ?
                  <>
                    <Img src={p_003_en}/>
                  </>
                  :
                  <>
                    <Img src={ImageUrls.p_003}/>
                  </>
              }
            </Box>
            <Box w={small ? "100%" : "47%"} mb="1.5rem">
              <Text fontSize="1.5rem" color="#414040" textAlign="center" fontWeight="500" mb="1.5rem">
                {t('projects.learning')}
              </Text>
              {
                lang === 'en-US'
                  ?
                  <>
                    <Img src={p_004_en}/>
                  </>
                  :
                  <>
                    <Img src={ImageUrls.p_004}/>
                  </>
              }
            </Box>
          </Flex>
        </Box>
      </Box>

      <Box position="relative" pt="4rem" pb="4rem" bg="#F8F9FA">
        <Box textAlign="center" pb="2rem">
          <Text color="#414040" fontSize="2rem" fontWeight="600" mb="3rem">
            {t('projects.plan')}
          </Text>
          {/* <Text fontSize="1.125rem" color="#999">
            项目期限：2023年6月17日-2028年6月16日
          </Text> */}
        </Box>

        <Box className="wrap">
          <Flex justify="space-between" position="relative" flexWrap="wrap" mb="1.25rem">
            <Box p="3px" mb="2rem" w={small ? "100%" : "50%"}>
              <Text textAlign="center" color="#4878F0" mb="1.25rem" fontSize="1.375rem">
                {t('projects.hospital.hospital')}
              </Text>
              <Box color="#999" fontSize="1.375rem">
                <Text>
                  {t('projects.hospital.line_1')}
                </Text>
                <Text>
                  {t('projects.hospital.line_2')}
                </Text>
              </Box>
            </Box>

            <Box p="3px" w={small ? "100%" : "50%"}>
              <Text textAlign="center" color="#4878F0" mb="1.25rem" fontSize="1.375rem">
                {t('projects.crowd.title')}
              </Text>
              <Box color="#999" fontSize="1.375rem">
                <Text>
                  {t('projects.crowd.line_1')}
                </Text>
                <Text>
                  {t('projects.crowd.line_2')}
                </Text>
              </Box>
            </Box>
          </Flex>

          <Box>
            {ProjectPlan.map((item) => {
              return (
                <Flex
                  justify="space-between"
                  position="relative"
                  flexWrap="wrap"
                  key={item.index}
                  alignItems="center"
                  mb="2rem"
                >
                  <Flex alignItems="center">
                    <Text color="#B2C5F6" fontSize="3rem" fontWeight="bold" mr="3rem">
                      {item.index}
                    </Text>
                    <Box fontSize="1.125rem" textAlign="center" mr="2rem">
                      <Text
                        bgColor="#4878F040"
                        borderRadius="20px"
                        lineHeight="2"
                        border="1px solid #4878F0"
                        p="0 1.25rem"
                        // mb="0.5rem"
                      >
                        {t(item.tag)}
                      </Text>
                      {/* <Text color="#4878F0">{item.time}</Text> */}
                    </Box>

                    <Text color="#4878F0" fontSize="1.625rem" fontWeight="bold">
                      {t(item.title)}
                    </Text>
                  </Flex>
                  {/* <Flex alignItems="center">
                    <Img src={ImageUrls.p_a} w="120px" mr="1rem" />
                    <Box maxW="320px">
                      {item.detail.map((t) => (
                        <Text fontSize="1.125rem" color="#999" lineHeight="1.75rem" key={t}>
                          {t}
                        </Text>
                      ))}
                    </Box>
                  </Flex> */}
                </Flex>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Project;
