import {ImageUrls} from "@/asset/icon";

export const BaseUrl: string = "https://www.sysdiagno.com:1338";

interface whoTypes {
  key: string,
  icon: string,
  cIcon: string,
  text: { t: string, h: boolean, us: boolean },
  text2: { t: Array<string>, h: boolean },
}

// 先思达是谁
export const HomeWho: whoTypes[] = [
  {
    key: "1",
    icon: "hIcon01",
    cIcon: "cIcon01",
    text: {t: "home.who.part_1.text_1", h: false, us: true},
    text2: {t: ["home.who.part_1.text_2"], h: true},
  },
  {
    key: "2",
    icon: "hIcon02",
    cIcon: "cIcon02",
    text: {t: "home.who.part_2.text_1", h: false, us: true},
    text2: {t: ["home.who.part_2.text_2"], h: true},
  },
  {
    key: "3",
    icon: "hIcon03",
    cIcon: "cIcon03",
    text: {t: "home.who.part_3.text_1", h: true, us: true},
    text2: {t: ["home.who.part_3.text_2"], h: false},
  },
  {
    key: "4",
    icon: "hIcon04",
    cIcon: "cIcon04",
    text: {t: "home.who.part_4.text_1", h: true, us: true},
    text2: {t: ["home.who.part_4.text_2"], h: false},
  },
];

export const NewsList = [
  {
    id: "1",
    thumb: ImageUrls.p_02,
    title: "北大-先思达糖组学联合实验室签约揭牌",
    summary: "本产品针对人血清糖蛋白糖链进行检测，通过分析得到相应数值，评估肝癌风险，辅助临床诊断。",
    time: "2013-2-3",
  },
  {
    id: "2",
    thumb: ImageUrls.p_02,
    title: "北大-先思达糖组学联合实验室签约揭牌",
    summary: "本产品针对人血清糖蛋白糖链进行检测，通过分析得到相应数值，评估肝癌风险，辅助临床诊断。",
    time: "2013-2-3",
  },
  {
    id: "3",
    thumb: ImageUrls.p_02,
    title: "北大-先思达糖组学联合实验室签约揭牌",
    summary: "本产品针对人血清糖蛋白糖链进行检测，通过分析得到相应数值，评估肝癌风险，辅助临床诊断。",
    time: "2013-2-3",
  },
  {
    id: "4",
    thumb: ImageUrls.p_02,
    title: "北大-先思达糖组学联合实验室签约揭牌",
    summary: "本产品针对人血清糖蛋白糖链进行检测，通过分析得到相应数值，评估肝癌风险，辅助临床诊断。",
    time: "2013-2-3",
  },
  {
    id: "5",
    thumb: ImageUrls.p_02,
    title: "北大-先思达糖组学联合实验室签约揭牌",
    summary: "本产品针对人血清糖蛋白糖链进行检测，通过分析得到相应数值，评估肝癌风险，辅助临床诊断。",
    time: "2013-2-3",
  },
  {
    id: "6",
    thumb: ImageUrls.p_02,
    title: "北大-先思达糖组学联合实验室签约揭牌",
    summary: "本产品针对人血清糖蛋白糖链进行检测，通过分析得到相应数值，评估肝癌风险，辅助临床诊断。",
    time: "2013-2-3",
  },
  {
    id: "7",
    thumb: ImageUrls.p_02,
    title: "北大-先思达糖组学联合实验室签约揭牌",
    summary: "本产品针对人血清糖蛋白糖链进行检测，通过分析得到相应数值，评估肝癌风险，辅助临床诊断。",
    time: "2013-2-3",
  },
  {
    id: "8",
    thumb: ImageUrls.p_02,
    title: "北大-先思达糖组学联合实验室签约揭牌",
    summary: "本产品针对人血清糖蛋白糖链进行检测，通过分析得到相应数值，评估肝癌风险，辅助临床诊断。",
    time: "2013-2-3",
  },
];

export const JoinList = [
  {
    id: "1",
    title: "生物研发工程师",
    title_en: "Biological R&D Engineer.",
    address: "南京·总部",
    icon: ImageUrls.join_01,
    c_icon: "",
  },
  {
    id: "2",
    title: "生物研发工程师",
    title_en: "Biological R&D Engineer.",
    address: "泰州·生产基地",
    icon: ImageUrls.join_03,
    c_icon: ImageUrls.join_02_c,
  },
  {
    id: "3",
    title: "生物研发工程师",
    title_en: "Biological R&D Engineer.",
    address: "北京·临床中心",
    icon: ImageUrls.join_03,
    c_icon: "",
  },
  {
    id: "4",
    title: "生物研发工程师",
    title_en: "Biological R&D Engineer.",
    address: "南京·总部",
    icon: ImageUrls.join_01,
    c_icon: "",
  },
];

export const TeamList = [
  {id: "1", name: "卢东黎", title: "博士", job: "研发总监", avatar: ImageUrls.lu, color: false},
  {id: "2", name: "陈萃英", title: "博士", job: "创始人/董事长 首席科学家", avatar: ImageUrls.chen, color: true},
  {id: "3", name: "卢东黎", title: "博士", job: "研发总监", avatar: ImageUrls.lu, color: false},
];
