import styled from "styled-components";

export const Div = styled.div`
  display: flex;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  .link {
    font-weight: 600;
    color: #dfdfdf;
    &.active {
      color: #fff;
    }
  }
`;

export const FooterGroup = styled.div`
  margin-left: 2rem;
  .title {
    color: #4878f0;
    margin-bottom: 1.5rem;
    display: block;
    outline: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .item {
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    display: block;
    outline: none;
    box-shadow: none;
    &:hover {
      color: #4878f0;
    }
  }
`;
