import { ImageUrls } from "@/asset/icon";
import { Box, Text } from "@chakra-ui/layout";
import { Image, Input } from "@chakra-ui/react";
import "./style.css";

const Admin: React.FC = () => {
  // const scrollToTop = () => {
  //   const sTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
  //   if (sTop > 0) {
  //     window.requestAnimationFrame(scrollToTop);
  //     window.scrollTo(0, sTop - sTop / 8);
  //   }
  // };
  return (
    <Box className="admin">
      <Box className="banner" position="relative">
        <Box>
          <Box>
            <Image src={ImageUrls.join} htmlWidth="100%" />
          </Box>
          <Box>
            <Box className="title">登陆</Box>

            <Box className="">
              <Text>账号</Text>
              <Input></Input>
            </Box>

            <Box>
              <Text>密码</Text>
              <Input type="password"></Input>
            </Box>

            <Box></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Admin;
