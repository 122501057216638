import About from "@/views/about";
import Admin from "@/views/admin";
import Contact from "@/views/contact";
import Home from "@/views/home";
import Join from "@/views/join";
import Job from "@/views/join/job";
import News from "@/views/news";
import Article from "@/views/news/article";
import Product from "@/views/product";
import Project from "@/views/project";

export type RouterType = {
  path: string;
  component: React.LazyExoticComponent<any> | React.FC;
  root: string[];
  notExect?: boolean;
  trans_text?: string;
  children?: any[];
  showNav?: boolean;
  showEn?: boolean;
};

// 总路由
const Routers: RouterType[] = [
  {
    path: "/home",
    component: Home,
    trans_text: "menu_home",
    showNav: true,
    showEn: true,
    root: [],
  },
  {
    path: "/about",
    component: About,
    trans_text: "menu_about",
    showNav: true,
    showEn: true,

    root: [],
    // children: [
    //   {
    //     name: "company",
    //     path: "/company",
    //     component: AboutCompany,
    //   },
    //   {
    //     name: "result",
    //     path: "/result",
    //     component: () => import("@/views/about/result"),
    //   },
    // ],
  },
  {
    path: "/product",
    component: Product,
    trans_text: "menu_p&s",
    showNav: true,
    showEn: true,
    root: [],
  },
  {
    path: "/project",
    component: Project,
    trans_text: "menu_project",
    showNav: true,
    showEn: true,
    root: [],
  },

  {
    path: "/news",
    component: News,
    trans_text: "menu_news",
    showNav: true,
    showEn: false,
    root: [],
  },
  {
    path: "/news/article",
    component: Article,
    trans_text: "",
    showNav: false,
    showEn: false,
    root: [],
  },
  {
    path: "/contact",
    component: Contact,
    trans_text: "menu_contact",
    showNav: true,
    showEn: true,
    root: [],
  },

  {
    path: "/join",
    component: Join,
    trans_text: "menu_join",
    showNav: true,
    showEn: false,
    root: [],
  },
  {
    path: "/join/job",
    component: Job,
    trans_text: "menu_join",
    showNav: false,
    root: [],
  },

  {
    path: "/admin",
    component: Admin,
    trans_text: "",
    showNav: false,
    root: [],
  },
];

export { Routers };
