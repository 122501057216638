import { usePageSize } from "@/App";
import { ImageUrls } from "@/asset/icon";
import { Box, Flex, Img, Text } from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

/** 核心团队 */
const Team: React.FC = () => {
  const pageSize = usePageSize();
  const {t} = useTranslation()

  return (
    <Box className={`${pageSize} Team`}>
      <Box className="wrap" textAlign="center" p="3rem 0 4rem">
        <Box className="title">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            { t('about.team.title') }
          </Text>
        </Box>
      </Box>

      {/* <Box background="#F8F9FA">
        <Box className="wrap" p="6rem 1.5rem">
          <Flex className="team-people">
            {TeamList.map((t) => (
              <Box key={t.id} className="item">
                <Box>
                  <Img src={t.avatar} />
                </Box>
                <Box className={`user-info ${t.color ? "color" : ""}`}>
                  <Box fontSize="1.5rem" mb="0.25rem">
                    {t.name} {t.title}
                  </Box>
                  <Box fontSize="1.25rem">{t.job}</Box>
                </Box>
              </Box>
            ))}
          </Flex>
        </Box>
      </Box> */}

      <Box p="4rem 0" bg={`url(${ImageUrls.team_bg_01}) no-repeat`}>
        <Box className="wrap">
          <Flex alignItems="center">
            <Box mr="2rem" w="720px">
              <Img src={ImageUrls.chen} />
            </Box>
            <Box>
              <Text color="#4878F0" fontSize="1.75rem" mb="0.5rem">
                { t('about.team.infos.name') }
              </Text>
              <Text mb="2.5rem" color="#666" fontSize="1.5rem">
                { t('about.team.infos.title') }
              </Text>
              <Text color="#999" fontSize="1.25rem" lineHeight="2">
                { t('about.team.infos.introduce') }
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>

      <Box p="4rem 0" bg={`url(${ImageUrls.team_bg_02}) no-repeat`}>
        <Box className="wrap">
          <Flex alignItems="center">
            <Text color="#333" fontSize="1.5rem" fontWeight="bold">
              “{ t('about.team.curve') }”
            </Text>
            <Img src={ImageUrls.tang} height="1.125rem" ml="1.5rem" />
          </Flex>

          <Box color="#666" fontSize="1.125rem" mb="3rem">
            <Text mt="2rem">
              { t('about.result.curve.intro.line_1') }
            </Text>
            <Text mt="2rem">
              • { t('about.result.curve.intro.line_2') }
            </Text>
            <Text>
              • { t('about.result.curve.intro.line_3') }
            </Text>
            <Text mt="2rem">
              • { t('about.result.curve.intro.line_4') }
            </Text>
          </Box>
          <Img src={ImageUrls.cyqx} mb="4rem" />
        </Box>

        <Box className="wrap">
          <Flex alignItems="center" mb="1.25rem">
            <Text color="#333" fontSize="1.5rem" fontWeight="bold">
              { t('about.team.results.liverDisease') }
            </Text>
            <Img src={ImageUrls.tang} height="1.125rem" ml="1.5rem" />
          </Flex>
          <ul style={{ listStyle: "auto", fontSize: "1.125rem", color: "#666", marginLeft: "1.5rem", lineHeight: "2" }}>
            <li>
              N-glycomic changes in hepatocellular carcinoma patients with liver cirrhosis induced by hepatitis B virus.
              Hepatology. 2007, 46(5):1426-35.
            </li>

            <li>
              Rating of CCl(4)-induced rat liver fibrosis by blood serum glycomics.J Gastroenterol Hepatol. 2007,
              22(7):1148-54.
            </li>
            <li>
              N-glycan profiles as tools in diagnosis of hepatocellular carcinoma and prediction of healthy human
              ageing. Mech Ageing Dev. 2009, 130(1-2):92-7.
            </li>

            <li>
              Serum protein N-glycans profiling for the discovery of potential biomarkers for nonalcoholic
              steatohepatitis.J Proteome Res. 2009, 8(2):463-70.{" "}
            </li>
            <li>5.Altered serum N-glycomics in chronic hepatitis B patients. Liver Int. 2010, 30(2):259-267.</li>
            <li>
              N-glycan based models improve diagnostic efficacies in hepatitis B virus-related hepatocellular carcinoma.
              Int J Cancer. 2010, 127(1):148-59.
            </li>
            <li>
              Serum N-glycome biomarker for monitoring development of DENA-induced hepatocellular carcinoma in rat. Mol
              Cancer. 2010, 9:215.
            </li>
            <li>
              Alteration of N -glycome in diethylnitrosamine-induced hepatocellular carcinoma mice: a non-invasive
              monitoring tool for liver cancer.Liver Int. 2010, 30(8):1221-8.
            </li>
            <li>
              Alteration of liver N-glycome in patients with hepatocellular carcinoma. Open Journal of Gastroenterology,
              2012, 2,1-8.
            </li>
            <li>血清N-糖组诊断模型在丙型肝炎患者肝纤维化评价中的应用[J]. 中华肝脏病杂志, 2020, 28(12):7.</li>
            <li>
              曹曦,孙艳玲,陈翠英等.HBV相关肝细胞癌患者血清N-糖组图谱改变与肝组织糖基转移酶表达变化的关系[J].临床肝胆病杂志,
              2021, 37(06):1336-1341.
            </li>
            <li>
              Serum N-Glycan Markers for Diagnosing Significant Liver Fibrosis and Cirrhosis in Chronic Hepatitis B
              Patients with Normal Alanine Aminotransferase Levels. Engineering, 2023, ISSN 2095-8099.
            </li>
          </ul>
          <Flex alignItems="center" mt="2.5rem" mb="1.25rem">
            <Text color="#333" fontSize="1.5rem" fontWeight="bold">
              { t('about.team.results.physiologicalAge') }
            </Text>
            <Img src={ImageUrls.tang} height="1.125rem" ml="1.5rem" />
          </Flex>
          <ul style={{ listStyle: "auto", fontSize: "1.125rem", color: "#666", marginLeft: "1.5rem", lineHeight: "2" }}>
            <li>N-glycomic changes in serum proteins during human aging. Rejuvenation Res. 2007, 10(4):521-531a.</li>

            <li>Human serum N-glycan profiles are age and sex dependent. Age Ageing. 2011, 40(5):568-575.</li>
            <li>Serum N-glycan profile shift during human ageing.Exp Gerontol. 2010, 45(10):738-43.</li>

            <li>
              N-glycomic biomarkers of biological aging and longevity: a link with inflammaging. Ageing Res Rev. 2013,
              12(2):685-98.
            </li>
            <li>
              Over-expression of heat shock protein 70 in mice is associated with growth retardation, tumor formation,
              and early death. Rejuvenation Res. 2008, 11(6):1013-20.
            </li>
          </ul>

          <Flex alignItems="center" mt="2.5rem" mb="1.25rem">
            <Text color="#333" fontSize="1.5rem" fontWeight="bold">
              { t('about.team.results.otherFields') }
            </Text>
            <Img src={ImageUrls.tang} height="1.125rem" ml="1.5rem" />
          </Flex>
          <ul style={{ listStyle: "auto", fontSize: "1.125rem", color: "#666", marginLeft: "1.5rem", lineHeight: "2" }}>
            <li>
              Altered serum glycomics in Alzheimer disease: a potential blood biomarker? Rejuvenation Res. 2010,
              13(4):439-444.
            </li>
            <li>
              Altered desialylated plasma N-glycan profile in patients with non-small cell lung carcinoma. Cancer
              Biomark. 2011-2012, 10(3-4):145-54.
            </li>
            <li>Cell surface-specific N-glycan profiling in breast cancer. PLoS One. 2013, 8(8):e72704. </li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

export default Team;
