export const PlatformCardList = [
  {
    title: "product.platformCard.specimens.title",
    index: "n_1",
    info: "product.platformCard.specimens.info",
    icon: "p_icon01",
  },
  {
    title: "product.platformCard.markers.title",
    index: "n_2",
    info: "product.platformCard.markers.info",
    icon: "p_icon02",
  },
  {
    title: "product.platformCard.profiling.title",
    index: "n_3",
    info: "product.platformCard.profiling.info",
    icon: "p_icon03",
  },
  {
    title: "product.platformCard.atlas.title",
    index: "n_4",
    info: "product.platformCard.atlas.info",
    icon: "p_icon04",
  },
  {
    title: "product.platformCard.analyze.title",
    index: "n_5",
    info: "product.platformCard.analyze.info",
    icon: "p_icon05",
  },
  {
    title: "product.platformCard.result.title",
    index: "n_6",
    info: "product.platformCard.result.info",
    icon: "p_icon06",
  },
];

export const PlatformAdvantage = [
  {
    advantage: "product.advantages.many.title",
    color: "#A27AFC",
    detail: [
      "product.advantages.many.details.item_1",
      "product.advantages.many.details.item_2"
    ],
  },
  {
    advantage: "product.advantages.fast.title",
    color: "#21cbba",
    detail: [
      "product.advantages.fast.details.item_1",
      "product.advantages.fast.details.item_2"
    ],
  },
  {
    advantage: "product.advantages.good.title",
    color: "#f4f664",
    detail: [
      "product.advantages.good.details.item_1",
      "product.advantages.good.details.item_2"
    ]
  },
  {
    advantage: "product.advantages.save.title",
    color: "#d9d9d9",
    detail: [
      "product.advantages.save.details.item_1",
      "product.advantages.save.details.item_2"
    ]
  },
  {
    advantage: "product.advantages.stable.title",
    color: "#98de6a",
    detail: [
      "product.advantages.stable.details.item_1",
      "product.advantages.stable.details.item_2"
    ]
  },
];

export const EstimateProduct = [
  {
    thumb: "p_03",
    title: "肝健康评估",
    desc: ["实时监测肝脏健康状态", "多维评估肝脏健康程度", "个性化肝健康科学管理"],
  },
  // {
  //   thumb: "p_05",
  //   title: "肝损伤评估/辅助诊断",
  //   desc: ["肝功能异常辅助诊断", "肝纤维化程度评估", "肝硬化辅助诊断"],
  // },
  // {
  //   thumb: "p_06",
  //   title: "肝癌辅助诊断",
  //   desc: ["肝癌早期筛查", "肝癌辅助诊断"],
  // },
];

export const EstimateAssessment = [
  {
    icon: "i_01_c",
    title: "product.diagnosisPages.gandajian.process.step.i_01",
    en: "place an order",
    index: "n_1",
    width: "33.3333%",
    arrowPosition: {
      right: "-11px",
      top: "calc(50% - 11px )",
    },
  },
  {
    icon: "i_02_c",
    title: "product.diagnosisPages.gandajian.process.step.i_02",
    en: "Home sampling",
    index: "n_2",
    width: "33.3333%",
    arrowPosition: {
      right: "-11px",
      top: "calc(50% - 11px )",
    },
  },
  {
    icon: "i_03_c",
    title: "product.diagnosisPages.gandajian.process.step.i_03",
    en: "Free postage for sample return",
    index: "n_3",
    width: "33.3333%",
    arrowPosition: {
      bottom: "-11px",
      left: "calc(50% - 11px )",
      transform: "rotate(90deg)",
    },
  },

  {
    icon: "i_05_c",
    title: "product.diagnosisPages.gandajian.process.step.i_05",
    en: "Issuance of evaluation report",
    index: "n_5",
    width: "50%",
    arrowPosition: {
      right: "-11px",
      top: "calc(50% - 11px )",
      transform: "rotate(180deg)",
    },
  },
  {
    icon: "i_04_c",
    title: "product.diagnosisPages.gandajian.process.step.i_04",
    en: "professional institutions for testing",
    index: "n_4",
    width: "50%",
  },
];

export const EstimateAssessmentSM = [
  {
    icon: "i_01_c",
    title: "product.diagnosisPages.gandajian.process.step.i_01",
    en: "place an order",
    index: "n_1",
    width: "33.3333%",
    arrowPosition: {
      right: "-11px",
      top: "calc(50% - 11px )",
    },
  },
  {
    icon: "i_02_c",
    title: "product.diagnosisPages.gandajian.process.step.i_02",
    en: "Home sampling",
    index: "n_2",
    width: "33.3333%",
    arrowPosition: {
      bottom: "-11px",
      left: "calc(50% - 11px )",
      transform: "rotate(90deg)",
    },
  },
  {
    icon: "i_04_c",
    title: "product.diagnosisPages.gandajian.process.step.i_04",
    en: "professional institutions for testing",
    index: "n_4",
    width: "50%",
    arrowPosition: {
      bottom: "-11px",
      left: "calc(50% - 11px )",
      transform: "rotate(90deg)",
    },
  },
  {
    icon: "i_03_c",
    title: "product.diagnosisPages.gandajian.process.step.i_03",
    en: "Free postage for sample return",
    index: "n_3",
    width: "33.3333%",
    arrowPosition: {
      left: "-11px",
      top: "calc(50% - 11px )",
      transform: "rotate(180deg)",
    },
  },

  {
    icon: "i_05_c",
    title: "product.diagnosisPages.gandajian.process.step.i_05",
    en: "Issuance of evaluation report",
    index: "n_5",
    width: "50%",
  },
];

export const DiagnosisAdvantage = [
  {
    index: "n_1",
    icon: "p_icon02",
    title: "product.diagnosisPages.advantages.list.n_1.title",
    detail: "product.diagnosisPages.advantages.list.n_1.detail",
  },
  {
    index: "n_2",
    icon: "p_icon01",
    title: "product.diagnosisPages.advantages.list.n_2.title",
    detail: "product.diagnosisPages.advantages.list.n_2.detail",
  },

  {
    index: "n_3",
    icon: "p_icon07",
    title: "product.diagnosisPages.advantages.list.n_3.title",
    detail: "product.diagnosisPages.advantages.list.n_3.detail",
  },
  {
    index: "n_4",
    icon: "p_icon06",
    title: "product.diagnosisPages.advantages.list.n_4.title",
    detail: "product.diagnosisPages.advantages.list.n_4.detail",
  },
  {
    index: "n_5",
    icon: "p_icon08",
    title: "product.diagnosisPages.advantages.list.n_5.title",
    detail: "product.diagnosisPages.advantages.list.n_5.detail",
  },
];

// 甘达健 产品优势
export const ProductAdvantage = [
  {
    index: "01",
    color: "#2466E8",
    icon: "p_icon02",
    title: "product.diagnosisPages.gandajian.advantages.list.01.title",
    detail: [
      "product.diagnosisPages.gandajian.advantages.list.01.item_1",
      "product.diagnosisPages.gandajian.advantages.list.01.item_2"
    ],
  },
  {
    index: "02",
    color: "#80ECA5",
    icon: "p_icon01",
    title: "product.diagnosisPages.gandajian.advantages.list.02.title",
    detail: [
      "product.diagnosisPages.gandajian.advantages.list.02.item_1",
      "product.diagnosisPages.gandajian.advantages.list.02.item_2"
    ]
  },

  {
    index: "03",
    color: "#808BEC",
    icon: "p_icon07",
    title: "product.diagnosisPages.gandajian.advantages.list.03.title",
    detail: [
      "product.diagnosisPages.gandajian.advantages.list.03.item_1",
      "product.diagnosisPages.gandajian.advantages.list.03.item_2"
    ],
  },
  {
    index: "04",
    color: "#FF8686",
    icon: "p_icon06",
    title: "product.diagnosisPages.gandajian.advantages.list.04.title",
    detail: [
      "product.diagnosisPages.gandajian.advantages.list.04.item_1",
      "product.diagnosisPages.gandajian.advantages.list.04.item_2"
    ],
  },
];

// 项目目的
export const ProjectPurpose = [
  {
    index: "01",
    color: "#F4F664",
    icon: "p_icon02",
    detail: ["projects.objective.list.01"],
  },
  {
    index: "02",
    color: "#21CBBA",
    icon: "p_icon01",
    detail: ["projects.objective.list.02"],
  },
  {
    index: "03",
    color: "#5585FF",
    icon: "p_icon07",
    detail: ["projects.objective.list.03"],
  },
];

// 项目计划
export const ProjectPlan = [
  {
    index: "01",
    tag: "projects.planList.01.tag",
    time: "2023",
    icon: "p_a",
    title: "projects.planList.01.title",
    detail: [
      "projects.planList.01.details.line_1"
    ],
  },
  {
    index: "02",
    tag: "projects.planList.02.tag",
    time: "2024-2027",
    icon: "p_a",
    title: "projects.planList.02.title",
    detail: [
      "projects.planList.02.details.line_1",
      "projects.planList.02.details.line_2"
    ],
  },
  {
    index: "03",
    tag: "projects.planList.03.tag",
    time: "2028",
    icon: "p_a",
    title: "projects.planList.03.title",
    detail: [
      "projects.planList.03.details.line_1"
    ],
  },
];
