import {usePageSize} from "@/App";
import {ImageUrls} from "@/asset/icon";
import {EstimateAssessment, EstimateAssessmentSM, ProductAdvantage} from "@/views/product/config";
import {Box, Flex, Img, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import i18n from "../../react-i18next-config";
import pop_en from '../../asset/image/english/pop_en.jpg'

const Estimate: React.FC = () => {
  const pageSize = usePageSize();
  const small = pageSize === "xs";

  const {t} = useTranslation()
  const lang = i18n.language

  const whyList = [
    {
      icon: "n_1",
      text: (
        <>
          {t('product.diagnosisPages.gandajian.why.list.01.text_1')}
          <sup>®</sup>
          {t('product.diagnosisPages.gandajian.why.list.01.text_2')}
        </>
      ),
      bg: "linear-gradient(90deg, #77CEC4 0%, rgba(119, 206, 196, 0) 100%)",
      translateY: 0,
    },
    {
      icon: "n_2",
      text: t('product.diagnosisPages.gandajian.why.list.02'),
      bg: "linear-gradient(90deg, #4878F0 0%, rgba(72, 120, 240, 0) 100%)",
      translateY: 6,
    },
    {
      icon: "n_3",
      text: t('product.diagnosisPages.gandajian.why.list.03'),
      bg: "linear-gradient(90deg, #77CEC4 0%, rgba(119, 206, 196, 0) 100%)",
      translateY: 0,
    },
    {
      icon: "n_4",
      text: t('product.diagnosisPages.gandajian.why.list.04'),
      bg: "linear-gradient(90deg, #4878F0 0%, rgba(72, 120, 240, 0) 100%)",
      translateY: 6,
    },
  ]

  return (
    <Box className="Estimate">
      <Box textAlign="center" maxW="1140px" m="auto" pb="2rem" pt="3rem">
        <Text color="#414040" fontSize="2rem" fontWeight="600">
          {t('product.diagnosisPages.title')}
        </Text>
      </Box>

      <Box textAlign="center" mb="5rem">
        <Text
          color="#4878F0"
          fontSize="1.5rem"
          p="2rem 1rem 1.5rem"
          borderBottom="4px solid #4878F0"
          display="inline-block"
        >
          {t('product.diagnosisPages.gandajian.title')}
          <sup>®</sup>
        </Text>
      </Box>

      <Box bgColor="#f8f9fa" position="relative">
        <Box className="wrap">
          <Img
            src={ImageUrls.p_03_1}
            maxW="616px"
            w="100%"
            position="absolute"
            top={lang === 'zh-CN' ? "-2.25rem" : "2.25rem"}
            right="0"
            className="product-thumb"
          />
          <Box/>
          <Box p="3rem 0" h={lang === 'en-US'  ? ' ' : '340px'  } className="info">
            <Text fontSize="1.5rem" fontWeight="700" color="#333" lineHeight="2rem">
              {t('product.diagnosisPages.title')}
            </Text>
            <Text fontSize="1.125rem" color="#666" maxW="482px" letterSpacing="0.5px" lineHeight="2rem">
              {t('product.diagnosisPages.gandajian.title')}
              <sup>®</sup>
              {t('product.diagnosisPages.gandajian.desc.line_1')}
              <sup>®</sup>
              {t('product.diagnosisPages.gandajian.desc.line_2')}
              {
                lang === 'zh-CN'
                  ?
                  <>
                    <sup>®</sup>
                    {t('product.diagnosisPages.gandajian.desc.line_3')}
                  </>
                  : <></>
              }
            </Text>
          </Box>
        </Box>
      </Box>

      {/*适用人群*/}
      <Box textAlign="center" maxW="1140px" m="auto" pb="1rem" pt="5rem">
        <Box textAlign="center">
          <Text color="#414040" fontSize="2rem" fontWeight="600" mb="4rem">
            {t('product.diagnosisPages.gandajian.users')}
          </Text>
          {
            lang === 'zh-CN'
              ?
              <>
                <Img src={ImageUrls.pop} fill="#000"/>
              </>
              :
              <>
                <Img src={pop_en} fill="#000"/>
              </>
          }
        </Box>
      </Box>

      {/*使用流程*/}
      <Box position="relative" pt="4rem" pb="4rem">
        <Box textAlign="center" pb="2rem">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('product.diagnosisPages.gandajian.process.title')}
          </Text>
        </Box>

        <Box className="wrap">
          <Box mb="1rem">
            <Text fontSize="1.625rem" fontWeight="#333">
              {t('product.diagnosisPages.gandajian.process.subTitle')}
            </Text>
          </Box>

          <Flex justify="space-between" position="relative" flexWrap="wrap">
            {(small ? EstimateAssessmentSM : EstimateAssessment).map((item) => {
              return (
                <Box w={small ? "50%" : item.width} key={item.icon} position="relative">
                  <Box p="1.5rem" m="1.25rem" boxShadow="5px 8px 36px 0px #0000001a">
                    <Img src={ImageUrls[item.icon]} w="3rem"/>
                    <Flex m="0.5rem 0" fontSize="1.5rem" color="#000" alignItems="center">
                      {t(item.title)}
                    </Flex>
                    <Text fontSize="0.625rem" color="#666" lineHeight="1.75rem" letterSpacing="1px" minH="2rem">
                      {item.en}
                    </Text>
                  </Box>
                  {item?.arrowPosition && <Img src={ImageUrls.es_07} position="absolute" {...item.arrowPosition} />}
                </Box>
              );
            })}
          </Flex>
        </Box>
      </Box>

      {/*产品优势*/}
      <Box position="relative" pt="4rem" pb="4rem">
        <Box textAlign="center" pb="2rem">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('product.diagnosisPages.advantages.title')}
          </Text>
        </Box>

        <Box className="wrap">
          <Flex justify="space-between" position="relative" flexWrap="wrap">
            {ProductAdvantage.map((item) => {
              return (
                <Flex w={small ? "50%" : "25%"} key={item.index} position="relative">
                  <Box p="3rem  0.5rem 1.5rem" m="1.25rem" textAlign="center" w="100%">
                    <Text fontSize="3rem" color={item.color} lineHeight="1.75rem" letterSpacing="1.5px">
                      {item.index}
                    </Text>
                    <Box m="1.5rem 0 0.75rem" fontSize="1.5rem" color="#000" alignItems="center">
                      {t(item.title)}
                    </Box>
                    {item.detail.map((text, index) => (
                      <Text fontSize="1.125rem" color="#999" lineHeight="1.75rem" letterSpacing="1.5px" key={index}>
                        {t(text)}
                      </Text>
                    ))}
                  </Box>
                </Flex>
              );
            })}
          </Flex>
        </Box>
      </Box>

      {/*选择甘达健的理由*/}
      <Box position="relative" p="6rem 0 7rem" bg="#f8f9fa" overflow="hidden">
        <Img
          src={ImageUrls.icon09}
          position="absolute"
          top="0"
          right={small ? "-4rem" : "0"}
          opacity={small ? "0.2" : "1"}
        />
        <Box textAlign="center" pb="2rem">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('product.diagnosisPages.gandajian.why.title.text_1')}
            <sup>®</sup>
            {t('product.diagnosisPages.gandajian.why.title.text_2')}
          </Text>
        </Box>

        <Flex className="wrap" justify="space-between" flexWrap="wrap">
          {whyList.map((it) => (
            <Box w={small ? "50%" : "25%"} transform={`translateY(${it.translateY}rem)`} key={it.icon}>
              <Box p="1rem">
                <Img src={ImageUrls[it.icon]} w="40px" h="auto"/>
                <Text m="0.5rem 0" h="5px" bg={it.bg}></Text>
                <Text fontSize="1.125rem" color="#999" lineHeight="2rem">
                  {it.text}
                </Text>
              </Box>
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default Estimate;
