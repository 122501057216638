import {usePageSize} from "@/App";
import {ImageUrls} from "@/asset/icon";
import {Box, Flex, Img, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import i18n from "../../react-i18next-config";


const Result: React.FC = () => {
  const pageSize = usePageSize();
  const small = pageSize === "xs";
  const {t} = useTranslation()
  const lang = i18n.language


  return (
    <Box className={`${pageSize} Result`}>
      <Box className="wrap" pt="3rem">
        <Box textAlign="center">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('about.result.title')}
          </Text>
        </Box>
      </Box>

      <Box bgColor="#f8f9fa" p="3.5rem 0" className="cirrhosis" mt="6rem">
        <Flex className="wrap" justify="space-between" flexWrap="wrap">
          <Box mt="1rem">
            <Text fontSize="1.375rem" fontWeight="500" color="#333" mb="0.5rem">
              {
                lang === 'en-US'
                  ?
                  <>
                    {t('about.result.report.subTitle.part_1')}
                    <br/>
                    {t('about.result.report.subTitle.part_2')}
                  </>
                  :
                  <>
                    {t('about.result.title')}
                  </>
              }
            </Text>
            <Text className="art" fontSize="1.25rem" color="#666" maxW="560px" lineHeight="2">
              {t('about.result.report.line_1')}
              <br/>
              {t('about.result.report.line_2')}
            </Text>
          </Box>
          <Box position={small ? "relative" : "absolute"} top={small ? "auto" : lang === 'zh-CN' ? "-8rem" : "-1rem"} right={small ? "auto" : "-2rem"}>
            <Img src={ImageUrls.r_01}/>
          </Box>
        </Flex>
      </Box>

      <Box className="wrap curve" pb="5rem" pt="10rem">
        <Box textAlign="center">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('about.result.curve.title')}
          </Text>
        </Box>
        <Box pt="3rem">
          <Flex position="relative" justify="space-between" flexWrap="wrap">
            <Box maxW="480px">
              <Text fontSize="1.5rem" color="#414040" fontWeight="540" mb="1.25rem">
                {t('about.result.curve.title')}
              </Text>
              <Text fontSize="1.125rem" color="#666" lineHeight="2">
                {t('about.result.curve.intro.line_1')}
                <br/>
                • {t('about.result.curve.intro.line_2')}
                <br/>
                • {t('about.result.curve.intro.line_3')}
                <br/>
                • {t('about.result.curve.intro.line_4')}
              </Text>
            </Box>
            <Box className="thumb" position="absolute" right="-3rem" bottom="-2rem">
              <Img src={ImageUrls.r_02} w="98%"/>
            </Box>
          </Flex>
        </Box>
      </Box>

      <Box
        bg="linear-gradient(94.61deg, rgba(10, 48, 182, 0.9) 19.1%, rgba(0, 32, 61, 0.9) 81.5%)"
        p="4rem 1.5rem"
        position="relative"
      >
        <Flex className="wrap" justifyContent="space-between">
          {small ? null : <Box></Box>}
          <Box color="white" letterSpacing="1px" borderLeft="4px solid white" pl="2.5rem" maxW="620px">
            <Text fontSize="1.5rem" fontWeight="600" mb="1.5rem">
              N-Glycomic Changes in Serum Proteins During Human Aging
            </Text>
            <Text fontSize="1.125rem" lineHeight="2">
              Valerie Vanhooren, Liesbeth Desmyter, Xue-En Liu, Maurizlo Cardell, Claudio Franceschi, Antonio Federico,
              Claude LibertWouter Laroy, Sylvlane Dewaele, Roland Contreras, and Cuiying Chen
            </Text>
          </Box>
        </Flex>
        <Img src={ImageUrls.icon07} position="absolute" left="0" bottom="0" opacity={small ? 0.32 : 1}/>
      </Box>

      <Box className="wrap glycan" pb="6rem" pt="5rem" textAlign="center">
        <Text fontSize="2rem" fontWeight="600">
          {t('about.result.tools.title')}
        </Text>
      </Box>

      <Box bgColor="#f8f9fa" p="3.5rem" className="humans">
        <Flex className="wrap" justify="space-between" flexWrap="wrap">
          <Box>
            <Text fontSize="1.25rem" fontWeight="600" color="#333" mb="1rem">
              {t('about.result.tools.subTitle')}
            </Text>
            <Text fontSize="1.125rem" maxW="560px" color="#666" lineHeight="2">
              {t('about.result.tools.line_1')}
              <br/>
              <br/>
              {t('about.result.tools.line_2')}
            </Text>
          </Box>
          <Img src={ImageUrls.r_03} position="absolute" top="-8rem" right="-2rem" className="thumb"/>
        </Flex>
      </Box>

      <Box className="wrap">
        <Flex className="mark" justify="space-between" flexWrap="wrap" mt="7rem" p="2rem 0">
          <Img src={ImageUrls.r_04}/>
          <Box maxW="510px" fontSize="1.125rem" color="#666" lineHeight="1.75" mb="1rem">
            <Text fontSize="1.25rem" fontWeight="600" color="#333" mb="0.5rem">
              {t('about.result.markers.part_1.title')}
            </Text>
            <Text fontSize="1.125rem" mb="1.5rem">
              {t('about.result.markers.part_1.line_1')}
              <br/>
              {t('about.result.markers.part_1.line_2')}
            </Text>
            <Text fontSize="1.25rem" fontWeight="600" color="#333" mb="0.5rem">
              {t('about.result.markers.part_2.title')}
            </Text>
            <Text fontSize="1.125rem">
              {t('about.result.markers.part_2.line_1')}
            </Text>
          </Box>
        </Flex>
      </Box>

      <Box
        position="relative"
        p="5rem"
        textAlign="center"
        bg="linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
linear-gradient(94.49deg, rgba(10, 48, 182, 0.9) 19.14%, rgba(0, 32, 61, 0.9) 102.06%)
"
      >
        <Img src={ImageUrls.icon10} position="absolute" left="0" bottom="0" opacity={small ? 0.32 : 1}/>

        <Text fontSize="2rem" color="white" fontWeight="600">
          {t('about.result.text_1')}
        </Text>
      </Box>

      <Box className="consensus" pt="4.5rem" pb="3.5rem" bg="#f8f9fa">
        <Flex className="wrap" justify="space-between" flexWrap="wrap">
          <Box maxW="559px">
            <Text fontSize="1.125rem" color="#666" lineHeight="2">
              {t('about.result.PU.line_1')}
              <br/>
              {t('about.result.PU.line_2')}
            </Text>
          </Box>
          <Box>
            <Img src={ImageUrls.r_05} maxW="499px" width="100%"/>
          </Box>
        </Flex>
      </Box>

      <Box
        position="relative"
        p="5.2rem"
        textAlign="center"
        bg="linear-gradient(94.49deg, rgba(10, 48, 182, 0.9) 19.14%, rgba(0, 32, 61, 0.9) 102.06%)"
        bgSize="cover"
      >
        <Img src={ImageUrls.icon11} position="absolute" right="0" bottom="0" opacity={small ? 0.32 : 1}/>
        <Text fontSize="1rem" color="#4878F0">
          {t('about.result.text_2.line_1')}
        </Text>
        <Text fontSize="2rem" color="white" fontWeight="700">
          {t('about.result.text_2.line_2')}
        </Text>
      </Box>

      <Box className="summery" pt="4rem" pb="3rem" bg="#f8f9fa">
        <Box className="wrap">
          <Text fontSize="1.125rem" color="#666" lineHeight="2" mb="2rem">
            {t('about.result.abstract')}
          </Text>
          <Img src={ImageUrls.about_11} mb="2rem"/>
        </Box>
      </Box>

      <Box position="relative" p="2rem 0 4rem" bg="white">
        <Box className="wrap">
          <Text fontSize="1.625rem" color="#000" mb="1rem">
            {t('about.result.glycomics.title')}
          </Text>
          <Text fontSize="1.125rem" color="#666" lineHeight="2">
            {t('about.result.glycomics.desc')}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Result;
