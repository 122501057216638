import {usePageSize} from "@/App";
import {ImageUrls} from "@/asset/icon";
import {HomeWho} from "@/constant";
import {NewsItem, SectionHeader, WhoItem} from "@/views/home/styled";
import {ArrowRightOutlined, RightCircleOutlined} from "@ant-design/icons";
import {Box, Flex, Text} from "@chakra-ui/layout";
import {Image, Link} from "@chakra-ui/react";
import {Link as ReachLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18n from "../../react-i18next-config";
import "./style.css";


const Home: React.FC = () => {
  const ps = usePageSize();
  const small = ps === "xs";
  const scrollToTop = () => {
    const sTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (sTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, sTop - sTop / 8);
    }
  };

  const {t} = useTranslation()

  const lang = i18n.language

  return (
    <Box className="Home">
      <Box className="banner" position="relative">
        {!small && (
          <>
            <Box
              className="card"
              p="2rem"
              mb="1.2rem"
              position="absolute"
              top="8rem"
              right="8rem"
              w="26rem"
              color="#fff"
              bgColor="#396cf090"
              borderRadius="4px"
            >
              <Box position="relative" zIndex="9">
                <Text fontSize="2.25rem">
                  {
                    lang === 'zh-CN'
                      ?
                      <>
                        全球糖组转化医学
                        <br/>
                        先行者
                      </>
                      :
                      <>
                        Global Pioneer in Glycomics Translational Medicine
                        <br/>
                      </>
                  }
                </Text>
                {
                  lang === 'zh-CN'
                    ? <Text fontSize="1.25rem">Global Pioneer in Glycomics Translational Medicine</Text>
                    : <></>
                }
                <Link as={ReachLink} to="/about">
                  <Text
                    mt="4rem"
                    p="0.5rem 2.5rem"
                    bgColor="#fff"
                    borderRadius="4px"
                    color="#4878f0"
                    display="inline-block"
                    cursor="pointer"
                  >
                    More <ArrowRightOutlined/>
                  </Text>
                </Link>
              </Box>
              <Box
                className="card"
                p="2rem"
                mb="1.2rem"
                position="absolute"
                top="-1rem"
                left="-1rem"
                w="100%"
                h="100%"
                color="#fff"
                bgColor="#396cf090"
                borderRadius="4px"
                opacity=".5"
              />
            </Box>
          </>
        )}
        {/* <Image src={ImageUrls.homeBg} htmlWidth="100%" /> */}
        <video id="video" src={ImageUrls.bg_m} loop autoPlay style={{width: "100%"}} muted/>
      </Box>

      <Box
        className="glycomics"
        position="relative"
        p="10rem 0 6rem"
        bg={`url(${ImageUrls.what_bg}) no-repeat`}
        bgSize="contain"
      >
        {!small && <Image src={ImageUrls.icon06} position="absolute" left="0" bottom="0" h="90%"/>}
        <Flex className="wrap" justifyContent="space-between">
          <Box></Box>
          <Box w="700px">
            <Text fontSize="2rem">{t('home.glycan.title')}</Text>
            <Text width="72px" height="5px" bg="#4878F0" mt="0.875rem"/>
            <Text color="#666" fontSize="1.125rem" lineHeight="2" mt="2rem">
              {
                lang === 'zh-CN'
                  ? <>{t('home.glycan.line_1')}</>
                  : <></>
              }
              <br/>
              {t('home.glycan.line_2')}
              <br/>
              {t('home.glycan.line_3')}
              <br/>
              {t('home.glycan.line_4')}
              {
                lang === 'en-US'
                  ? <><br/> {t('home.glycan.line_5')}</>
                  : <></>
              }
            </Text>
            <Box textAlign="right" mt="4rem">
              <Image src={ImageUrls.icon02} display="inline-block"/>
            </Box>
          </Box>
        </Flex>
      </Box>

      {/* 先思达是谁 */}
      <Box
        className="who"
        position="relative"
        background={`url(${ImageUrls.homeBg02})`}
        bgSize="cover"
        p="5rem 0 15rem"
      >
        <Box className="wrap" zIndex="1" color="white">
          <SectionHeader color="white" title="white">
            <Text className="title">{t('home.about.title')}</Text>
            <Text className="line"></Text>
          </SectionHeader>
          <Box fontSize="1.125rem" letterSpacing="1px">
            <Text lineHeight="2rem" mb="2rem">
              {t('home.about.content.line_1')}
              <sup>®</sup>
              {t('home.about.content.line_2')}
              <sup>®</sup>
              {t('home.about.content.line_3')}
            </Text>
            <Text lineHeight="2rem">
              {t('home.about.part_2.line_1')}
              {t('home.about.part_2.line_2')}
              <sup>®</sup>
              {t('home.about.part_2.line_3')}
              <sup>®</sup>
              {t('home.about.part_2.line_4')}
            </Text>
          </Box>

          {small ? (
            <Box className="cate" mt="2rem">
              <Flex justifyContent="space-between" pb="1rem" flexWrap="wrap">
                {HomeWho.map((item) => {
                  return (
                    <Flex key={item.key} color="#4878f0" w="50%" textAlign="center">
                      <Box m="0.375rem" bg="white" w="100%" pb="2rem" borderRadius="4px">
                        <Image w="50%" src={ImageUrls[item.cIcon]} display="inline-block" p="1.5rem"/>
                        {
                          lang === 'en-US'
                            ?
                            <>
                              <Text fontWeight={item.text.h || item.text.us ? "600" : "400"}>
                                {t(item.text.t)}
                              </Text>
                              {
                                item.text2.t.map((text) => {
                                  return (
                                    <Text fontWeight={item.text2.h && !item.text.us ? "600" : "400"}
                                          key={text}>
                                      {t(text)}
                                    </Text>
                                  );
                                })
                              }
                            </>
                            :
                            <>
                              <Text fontWeight={item.text.h ? "600" : "400"}>{t(item.text.t)}</Text>
                              {
                                item.text2.t.map((text) => {
                                  return (
                                    <Text fontWeight={item.text2.h ? "600" : "400"} key={text}>
                                      {t(text)}
                                    </Text>
                                  );
                                })
                              }
                            </>
                        }
                      </Box>
                    </Flex>
                  );
                })}
              </Flex>
            </Box>
          ) : (
            <Box position="absolute" w="90%" left="5%" bottom="-25rem">
              <Flex justifyContent="space-between" pb="4rem" h="320px" m="0 -1rem">
                {HomeWho.map((item) => {
                  return (
                    <WhoItem key={item.key} url={ImageUrls[item.cIcon]}
                             hurl={ImageUrls[item.icon]}>
                      <Box fontSize="20px" className="icon"/>
                      {
                        lang === 'en-US'
                          ?
                          <>
                            <Text fontWeight={item.text.h || item.text.us ? "600" : "400"}>
                              {t(item.text.t)}
                            </Text>
                            {
                              item.text2.t.map((text) => {
                                return (
                                  <Text fontWeight={item.text2.h && !item.text.us ? "600" : "400"}
                                        key={text}
                                        fontSize="18px"
                                  >
                                    {t(text)}
                                  </Text>
                                );
                              })
                            }
                          </>
                          :
                          <>
                            <Text fontWeight={item.text.h ? "600" : "400"}>{t(item.text.t)}</Text>
                            {
                              item.text2.t.map((text) => {
                                return (
                                  <Text fontWeight={item.text2.h ? "600" : "400"} key={text}>
                                    {t(text)}
                                  </Text>
                                );
                              })
                            }
                          </>
                      }
                    </WhoItem>
                  );
                })}
              </Flex>
            </Box>
          )
          }
        </Box>
      </Box>

      {/* 平台与产品 */}
      <Box className="wrap product" pb="3rem" pt={small ? "8rem" : "16rem"}>
        <SectionHeader>
          <Text className="title">{t('home.product.title')}</Text>
          <span className="line"></span>
        </SectionHeader>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Box className="product-card" mb="2rem" p="1rem" w="480px">
            <Text bg="#0e3d95" fontWeight="600" fontSize="1.5rem" color="white" textAlign="center"
                  lineHeight="2">
              {t('home.product.glyFace.title')}
              <sup>®</sup>
            </Text>
            <Box className="p-thumb" position="relative">
              <Image src={ImageUrls.p_01} m="0"/>
              <Box
                className="btn"
                position="absolute"
                w="100%"
                h="100%"
                top="0"
                left="0"
                bg="#132552aa"
                textAlign="center"
                opacity="0"
              >
                <Link as={ReachLink} to="/product" onClick={scrollToTop}>
                  <Text
                    mt="7rem"
                    p="1rem 2.5rem"
                    color="#fff"
                    borderRadius="4px"
                    bg="#4878f0"
                    display="inline-block"
                    cursor="pointer"
                  >
                    {t('home.product.details')} <RightCircleOutlined/>
                  </Text>
                </Link>
              </Box>
            </Box>
            <Text color="#4878f0" fontSize="1.5rem" m="1rem 0 0.625rem">
              {t('home.product.glyFace.title')}<sup>®</sup>
            </Text>
            <Text fontSize="1.625rem" fontWeight="400">
              {t('home.product.glyFace.sub')}
            </Text>
            <Text fontSize="1rem" color="#999">
              {t('home.product.glyFace.desc.line_1')}
              <sup>®</sup>
              {t('home.product.glyFace.desc.line_2')}
              <sup>®</sup>
              {t('home.product.glyFace.desc.line_3')}
            </Text>
          </Box>
          <Box className="product-card" mb="2rem" p="1rem" w="480px">
            <Text bg="#0e3d95" fontWeight="600" fontSize="1.5rem" color="white" textAlign="center"
                  lineHeight="2">
              {t('home.product.jietaisi.title')}
              <sup>®</sup>
            </Text>
            <Box className="p-thumb" position="relative">
              <Image src={ImageUrls.p_02} m="0"/>
              <Box
                className="btn"
                position="absolute"
                w="100%"
                h="100%"
                top="0"
                left="0"
                bg="#132552aa"
                textAlign="center"
                opacity="0"
              >
                <Link as={ReachLink} to="/product?tab=diagnosis" onClick={scrollToTop}>
                  <Text
                    mt="7rem"
                    p="1rem 2.5rem"
                    color="#fff"
                    borderRadius="4px"
                    bg="#4878f0"
                    display="inline-block"
                    cursor="pointer"
                  >
                    {t('home.product.details')} <RightCircleOutlined/>
                  </Text>
                </Link>
              </Box>
            </Box>
            <Text color="#4878f0" fontSize="1.5rem" m="1rem 0 0.625rem">
              {t('home.product.jietaisi.title')}
              <sup>®</sup>
            </Text>
            <Text fontSize="1.625rem" fontWeight="400">
              {t('home.product.jietaisi.sub')}
            </Text>
            <Text fontSize="1rem" color="#999">
              {t('home.product.jietaisi.desc.line_1')}
              <sup>®</sup>
              {t('home.product.jietaisi.desc.line_2')}
              <sup>®</sup>
              {t('home.product.jietaisi.desc.line_3')}
            </Text>
          </Box>
        </Flex>
      </Box>

      {/* 新闻资讯 */}
      <Box className="news" bg="#F2F9FE" pt="4rem" pb="4rem" position="relative" display="none">
        <Box className="wrap">
          <SectionHeader>
            <Text className="title">新闻资讯</Text>
            <span className="line"></span>
          </SectionHeader>

          <Flex justifyContent="center" flexWrap="wrap">
            <Box className="NewsCard">
              <Box position="relative">
                <Image src={ImageUrls.n_01} w="540px"/>
                <Flex className="card" alignItems="center">
                  <span className="month">10</span>
                  <span className="Date">2022-9</span>
                  <span>FFMI肿瘤标志物最新发明奖</span>
                </Flex>
              </Box>
            </Box>

            <Box ml="2.25rem" mt="3rem">
              {[1, 2, 3].map((k, i) => (
                <NewsItem key={k} last={i !== 2}>
                  <Flex>
                    <Box className="bank" color="#4878f0" w="120px">
                      <Text className="month" fontSize="2.25rem" fontWeight="600">
                        10
                      </Text>
                      <Text className="Date" fontSize="0.875rem">
                        2022-9
                      </Text>
                    </Box>
                    <Box className="info">
                      <Text className="title" color="#333" fontSize="1.25rem" fontWeight="500" mb="0.5rem">
                        FFMI肿瘤标志物最新发明奖
                      </Text>
                      <Text className="desc" color="#999" fontSize="1.125rem">
                        血液标志物用于临床肝细胞癌早期筛查的专家共识
                      </Text>
                    </Box>
                  </Flex>
                </NewsItem>
              ))}
            </Box>
          </Flex>
          <Image src={ImageUrls.icon02} mt="4rem"/>
        </Box>
        {
          !small && <Image src={ImageUrls.icon01} position="absolute" top="0" right="0"/>
        }
      </Box>

      { /* 重点科研合作伙伴 */}
      <Box className="wrap" pt="4rem" pb="4rem">
        <SectionHeader>
          <Text className="title">{t('home.partners.title')}</Text>
          <span className="line"></span>
        </SectionHeader>

        <Flex justify="space-around" flexWrap="wrap">
          {[
            {thumb: "hp_01", title: "home.partners.thumb", time: "2021"},
            {thumb: "hp_02", title: "home.partners.thumb", time: "2023"},
          ].map((item) => (
            <Flex flexDirection="column" textAlign="center" color="#6C87A6" key={item.thumb} mb="2rem">
              <Box boxShadow="4px 11px 30px 0px #0037784D" p="0.25rem" borderRadius="0.5rem" mb="1rem">
                <Image src={ImageUrls[item.thumb]} maxWidth="352px" width="100%"/>
              </Box>
              <Text fontSize="1.125rem">{t(item.title)}</Text>
              <Text fontSize="1.5rem" fontWeight="600">
                {item.time}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Image src={ImageUrls.hp_bg} htmlWidth="100%" fit="contain" position="absolute" bottom="0"/>
      </Box>
    </Box>
  );
};

export default Home;
