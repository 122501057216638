import styled from "styled-components";

export const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 3.125rem;
  position: relative;
  .en {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: ${(props) => props.color || "#4878F0"};
  }
  .title {
    font-size: 2rem;
    font-weight: 600;
    color: ${(props) => props.title || "black"};
  }
  .line {
    width: 70px;
    height: 5px;
    background: ${(props) => props.color || "#4878F0"};
    display: inline-block;
  }
`;

export const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 3.125rem;
  position: relative;
  .en {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: ${(props) => props.color || "#4878F0"};
  }
  .title {
    font-size: 2rem;
    font-weight: 600;
    color: ${(props) => props.title || "black"};
  }
  .line {
    width: 70px;
    height: 5px;
    background: ${(props) => props.color || "#4878F0"};
    display: inline-block;
  }
`;

export const ProductCard = styled.div`
  padding: 2rem;
  width: 480px;
  .header {
    background: #0e3d95;
    font-weight: 600;
    font-size: 1.5rem;
    color: white;
    text-align: center;
    line-height: 2;
  }
  .company {
    color: #4878f0;
    font-size: 1.5rem;
    margin: 1rem 0 0.625rem;
  }
  .title {
    font-size: 22px;
    font-weight: 400;
  }
  .desc {
    font-size: 16px;
    color: #999;
  }
`;

export const NewsCard = styled.div`
  .card {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    font-size: 1.25rem;
    background: #2959d3bf;
    .month {
      font-size: 3rem;
      margin-right: 1rem;
      margin-left: 1.5rem;
    }
    .Date {
      margin-right: 1rem;
    }
  }
`;

export const NewsItem = styled.div<{ last: boolean }>`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #cfdef8;
  border-bottom-width: ${(props) => (props?.last ? 1 : 0)}px;
  .bank {
    color: #4878f0;
    font-size: 2.25rem;
    .Date {
      font-size: 0.875rem;
    }
  }
`;

export const WhoItem = styled.div<{ url: string; hurl: string}>`
  height: 270px;
  width: 250px;
  color: #4878f0;
  background-color: white;
  text-align: center;
  margin: 1rem;
  //padding-top: 1rem;
  //padding-bottom: 1rem;
  padding: 0.5rem;
  font-size: 20px;
  box-shadow: 0 4px 30px 0 #05328626;
  border-radius: 4px;
  transition: all 0.5s;
  position: relative;
  .icon {
    width: 60px;
    height: 60px;
    background: ${(props) => `url(${props.url})`};
    background-size: contain;
    display: inline-block;
    margin: 2rem auto;
  }

  // &:hover {
  //   color: white;
  //   background-color: #4878f0;
  //   height: 310px;
  //   .icon {
  //     background: ${(props) => `url(${props.hurl})`};
  //     background-size: contain;
  //   }
  //   &::after {
  //     position: absolute;
  //     bottom: 2rem;
  //     left: 50%;
  //     margin-left: -2.5rem;
  //     content: "";
  //     width: 5rem;
  //     height: 0.125rem;
  //     background: #80eca5;
  //   }
  // }
`;
