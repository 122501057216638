import ProductJ from "@/views/product/productJ";
import { Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import {useTranslation} from "react-i18next";

const Diagnosis: React.FC = () => {
  const [productType, setType] = useState("J");

  const {t} = useTranslation()

  return (
    <Box className="Diagnosis">
      <Box textAlign="center" maxW="1140px" m="auto" pb="2rem" pt="3rem">
        <Text color="#414040" fontSize="2rem" fontWeight="600">
          { t('product.diagnosisPages.title') }
        </Text>
      </Box>

      <Box textAlign="center" mb="5rem">
        <Text
          fontSize="1.5rem"
          p="2rem 1rem 1.5rem"
          borderBottom="4px solid #4878F0"
          display="inline-block"
          cursor="pointer"
          onClick={() => {
            setType("J");
          }}
          borderColor={productType === "J" ? " #4878F0" : "#d9d9d9"}
          color={productType === "J" ? " #4878F0" : "#666"}
        >
          { t('product.diagnosisPages.jietaisi') }
          <sup>®</sup>
        </Text>
        {/* <Text
          fontSize="1.5rem"
          p="2rem 1rem 1.5rem"
          borderBottom="4px solid #4878F0"
          display="inline-block"
          cursor="pointer"
          onClick={() => {
            setType("G");
          }}
          borderColor={productType === "G" ? " #4878F0" : "#d9d9d9"}
          color={productType === "G" ? " #4878F0" : "#666"}
        >
          甘达朗<sup>®</sup>
        </Text> */}
      </Box>
      <ProductJ></ProductJ>
      {/* {productType === "J" ? <ProductJ></ProductJ> : <ProductG></ProductG>} */}
    </Box>
  );
};

export default Diagnosis;
