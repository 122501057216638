import { ImageUrls } from "@/asset/icon";
import { service } from "@/service/request";
import { Box, Text } from "@chakra-ui/layout";
import { Flex, Image, Link } from "@chakra-ui/react";
import moment from "antd/node_modules/moment";
import { useEffect, useState } from "react";
import { Link as ReachLink, useLocation } from "react-router-dom";
import "./style.css";

const Job: React.FC = () => {
  const scrollToTop = () => {
    const sTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (sTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, sTop - sTop / 8);
    }
  };

  const [data, setData] = useState([] as any);
  const { search } = useLocation();
  useEffect(() => {
    if (search) {
      const t = search?.split("=")[1];
      (async () => {
        const { data = {} } = (await service.get(`/api/jobs/${t}`)) as any;

        setData({
          id: data.id,
          ...data.attributes,
          updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
      })();
    }
  }, [search]);

  return (
    <Box className="job">
      <Box maxW="960px" p="3rem 2rem" m="auto">
        <Box className="nav" mb="3rem">
          <Link as={ReachLink} to={`/join`} onClick={scrollToTop}>
            <Text color="#4878f0" display="inline-block" cursor="pointer" mr="0.5rem">
              加入我们
            </Text>
          </Link>
          / {data.job_name}
        </Box>
        <Box textAlign="center" mb="4rem">
          <Text color="#333" fontSize="2rem" mb="0.5rem">
            {data.job_name}
          </Text>
          <Text color="#666" fontSize="1rem">
            更新时间：{data.updatedAt}
          </Text>
        </Box>

        <Flex mb="1.25rem" alignItems="center">
          <Text fontSize="1.5rem">岗位职责</Text>
          <Image src={ImageUrls.tang} ml="1rem" flexShrink={0} height="1.375rem" />
        </Flex>

        <Box fontSize="1.125rem" color="#666" lineHeight="1.8" mb="8rem">
          <div dangerouslySetInnerHTML={{ __html: data.job_description }}></div>
        </Box>
      </Box>
    </Box>
  );
};

export default Job;
