import { FooterBar } from "@/components/footer";
import Layout from "@/components/layout";
import { Nav } from "@/components/nav";
import { ChakraProvider } from "@chakra-ui/react";
import "antd/dist/antd.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import "./App.css";
import i18n from "./react-i18next-config";
import { Routers } from "./routers";

export const usePageSize = () => {
  const [className, setName] = useState("");
  const getBrowserWidth = () => {
    const wWidth = window.document.documentElement.clientWidth;
    if (wWidth < 520) {
      setName("xs");
      document.getElementsByTagName("html")[0].style.cssText = "font-size: 12px";
    } else if (wWidth < 991) {
      setName("sm");
    } else if (wWidth < 1199) {
      setName("md");
    } else {
      setName("lg");
    }
  };

  useEffect(() => {
    getBrowserWidth();
  }, []);

  return className;
};

const App = () => {
  const pageSize = usePageSize();

  const { t } = useTranslation();
  const [language, setLanguage] = useState("zh-CN");
  const { pathname } = useLocation();
  const changeLanguage = async (value: any) => {
    setLanguage(value);
    await i18n.changeLanguage(value);
  };

  useEffect(() => {
    let type = localStorage.getItem("i18nextLng");
    if (type) {
      setLanguage(type);
    }
  }, []);

  return (
    <ChakraProvider>
      <Layout>
        {pathname !== "/admin" && (
          <Nav pageSize={pageSize} t={t} language={language} changeLanguage={changeLanguage}></Nav>
        )}
        <Switch>
          {Routers.map((router) => {
            return <Route exact={!router.notExect} key={router.path} path={router.path} component={router.component} />;
          })}
          <Redirect path="/" to="/home" />
        </Switch>
        {pathname !== "/admin" && <FooterBar />}
      </Layout>
    </ChakraProvider>
  );
};

export default withRouter(App);

// if (router?.children) {
//   return (
//     <Route
//       key={router.path}
//       path={router.path}
//       render={() => (
//         <router.component>
//           {router?.children?.map((ro) => {
//             console.log("🚀 ~ file: App.tsx:63 ~ ro:", ro);
//             return <Route key={ro.name} path={`${router.path}${ro.path}`} render={() => ro.component} />;
//           })}
//         </router.component>
//       )}
//     />
//   );
// }
