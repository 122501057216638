import about_11 from "./image/about_11.png";
import about_banner from "./image/about_banner.png";
import ab_01 from "./image/ab_01.png";
import ab_02 from "./image/ab_02.png";
import ab_user from "./image/ab_user.png";
import arrow from "./image/arrow.png";
import avatar_1 from "./image/avatar_1.png";
import avatar_2 from "./image/avatar_2.png";
import bg_01 from "./image/bg_01.png";
import bg_02 from "./image/bg_02.png";
import { default as bg_m, default as bg_p } from "./image/bg_m.mp4";
import chen from "./image/chen.png";
import ch_i_01 from "./image/ch_i_01.png";
import ch_i_02 from "./image/ch_i_02.png";
import ch_i_03 from "./image/ch_i_03.png";
import ch_i_04 from "./image/ch_i_04.png";
import cIcon01 from "./image/cIcon01.png";
import cIcon02 from "./image/cIcon02.png";
import cIcon03 from "./image/cIcon03.png";
import cIcon04 from "./image/cIcon04.png";
import ct_01 from "./image/ct_01.png";
import ct_02 from "./image/ct_02.png";
import ct_03 from "./image/ct_03.png";
import ct_04 from "./image/ct_04.png";
import ct_bg from "./image/ct_bg.png";
import cyqx from "./image/cyqx.png";
import c_bg from "./image/c_bg.png";
import es_01 from "./image/es_01.png";
import es_02 from "./image/es_02.png";
import es_03 from "./image/es_03.png";
import es_04 from "./image/es_04.png";
import es_05 from "./image/es_05.png";
import es_06 from "./image/es_06.png";
import es_07 from "./image/es_07.png";
import e_01 from "./image/e_01.png";
import e_bg from "./image/e_bg.png";
import gh from "./image/gh.png";
import g_bg from "./image/g_bg.png";
import hIcon01 from "./image/hIcon01.png";
import hIcon02 from "./image/hIcon02.png";
import hIcon03 from "./image/hIcon03.png";
import hIcon04 from "./image/hIcon04.png";
import homeBg02 from "./image/homeBg02.png";
import homeBg from "./image/home_bg.png";
import hp_01 from "./image/hp_01.png";
import hp_02 from "./image/hp_02.png";
import hp_bg from "./image/hp_bg.png";
import icon01 from "./image/icon01.png";
import icon02 from "./image/icon02.png";
import icon03 from "./image/icon03.png";
import icon04 from "./image/icon04.png";
import icon05 from "./image/icon05.png";
import icon06 from "./image/icon06.png";
import icon07 from "./image/icon07.png";
import icon08 from "./image/icon08.png";
import icon09 from "./image/icon09.png";
import icon10 from "./image/icon10.png";
import icon11 from "./image/icon11.png";
import icon20 from "./image/icon20.png";
import i_01_c from "./image/i_01_c.png";
import i_02_c from "./image/i_02_c.png";
import i_03_c from "./image/i_03_c.png";
import i_04_c from "./image/i_04_c.png";
import i_05_c from "./image/i_05_c.png";
import join from "./image/join.png";
import join_01 from "./image/join_01.png";
import join_02_c from "./image/join_02_c.png";
import join_03 from "./image/join_03.png";
import join_bg from "./image/join_bg.png";
import join_item_bg from "./image/join_item_bg.png";
import logo from "./image/logo.png";
import lu from "./image/lu.png";
import more from "./image/more.png";
import m_01 from "./image/m_01.png";
import m_02 from "./image/m_02.png";
import m_03 from "./image/m_03.png";
import nb_1 from "./image/nb_1.png";
import nb_2 from "./image/nb_2.png";
import nb_3 from "./image/nb_3.png";
import nb_4 from "./image/nb_4.png";
import nc_1 from "./image/nc_1.png";
import nc_2 from "./image/nc_2.png";
import nc_3 from "./image/nc_3.png";
import nc_4 from "./image/nc_4.png";
import news_banner from "./image/news_banner.png";
import n_01 from "./image/n_01.png";
import n_1 from "./image/n_1.png";
import n_2 from "./image/n_2.png";
import n_3 from "./image/n_3.png";
import n_4 from "./image/n_4.png";
import n_5 from "./image/n_5.png";
import n_6 from "./image/n_6.png";
import pop from "./image/pop.png";
import product_banner from "./image/product_banner.png";
import p_001 from "./image/p_001.png";
import p_002 from "./image/p_002.png";
import p_003 from "./image/p_003.png";
import p_004 from "./image/p_004.png";
import p_01 from "./image/p_01.png";
import p_02 from "./image/p_02.png";
import p_03 from "./image/p_03.png";
import p_03_1 from "./image/p_03_1.png";
import p_04 from "./image/p_04.png";
import p_05 from "./image/p_05.png";
import p_06 from "./image/p_06.png";
import p_a from "./image/p_a.png";
import p_g from "./image/p_g.png";
import p_icon01 from "./image/p_icon01.png";
import p_icon02 from "./image/p_icon02.png";
import p_icon03 from "./image/p_icon03.png";
import p_icon04 from "./image/p_icon04.png";
import p_icon05 from "./image/p_icon05.png";
import p_icon06 from "./image/p_icon06.png";
import p_icon07 from "./image/p_icon07.png";
import p_icon08 from "./image/p_icon08.png";
import p_j from "./image/p_j.png";
import p_md_bg from "./image/p_md_bg.png";
import qrCode from "./image/qrCode.png";
import r_01 from "./image/r_01.png";
import r_02 from "./image/r_02.png";
import r_03 from "./image/r_03.png";
import r_04 from "./image/r_04.png";
import r_05 from "./image/r_05.png";
import s_bg from "./image/s_bg.png";
import tang from "./image/tang.png";
import team_bg_01 from "./image/team_bg_01.png";
import team_bg_02 from "./image/team_bg_02.png";
import what_bg from "./image/what_bg.png";

export const ImageUrls: { [key: string]: string } = {
  lu,
  gh,
  chen,
  icon20,

  about_11,

  cyqx,
  team_bg_01,
  team_bg_02,

  ch_i_01,
  ch_i_02,
  ch_i_03,
  ch_i_04,

  arrow,
  tang,

  bg_p,
  bg_m,
  p_a,
  p_md_bg,

  i_01_c,
  i_02_c,
  i_03_c,
  i_04_c,
  i_05_c,

  p_001,
  p_002,
  p_003,
  p_004,

  what_bg,
  news_banner,
  join,
  join_01,
  join_02_c,
  join_03,
  join_bg,
  join_item_bg,
  more,

  ct_bg,
  ct_01,
  ct_02,
  ct_03,
  ct_04,

  p_icon01,
  p_icon02,
  p_icon03,
  p_icon04,
  p_icon05,
  p_icon06,
  p_icon07,
  p_icon08,

  bg_01,
  bg_02,

  c_bg,

  n_1,
  n_2,
  n_3,
  n_4,
  n_5,
  n_6,

  nb_1,
  nb_2,
  nb_3,
  nb_4,

  nc_1,
  nc_2,
  nc_3,
  nc_4,

  homeBg,
  s_bg,
  g_bg,
  homeBg02,
  logo,
  icon01,
  icon02,
  icon03,
  icon04,
  icon05,
  icon06,
  icon07,
  icon08,
  icon09,
  icon10,
  icon11,

  hIcon01,
  hIcon02,
  hIcon03,
  hIcon04,
  cIcon01,
  cIcon02,
  cIcon03,
  cIcon04,

  p_01,
  p_02,
  p_03,
  p_03_1,
  p_04,
  p_05,
  p_06,
  pop,

  es_01,
  es_02,
  es_03,
  es_04,
  es_05,
  es_06,
  es_07,

  n_01,
  hp_01,
  hp_02,
  hp_bg,
  e_01,
  e_bg,
  qrCode,
  ab_01,
  ab_02,
  m_01,
  m_02,
  m_03,

  r_01,
  r_02,
  r_03,
  r_04,
  r_05,

  p_j,
  p_g,

  avatar_1,
  avatar_2,
  product_banner,
  about_banner,
  ab_user,
};
