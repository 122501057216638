import { usePageSize } from "@/App";
import { ImageUrls } from "@/asset/icon";
import { Box, Flex, Img, Text } from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

const Company: React.FC = () => {
  const pageSize = usePageSize();
  const small = pageSize === "xs";
  const {t} = useTranslation()
  return (
    <Box className={`${pageSize} Company`}>
      <Box className="wrap" textAlign="center" pb="5rem" pt="3rem">
        <Box className="title" mb="2rem">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            { t('about.company.profile') }
          </Text>
        </Box>
        <Text className="info" fontSize="1.125rem" color="#666" lineHeight="3rem">
          { t('about.company.content.line_1') }
          <sup>®</sup>
          { t('about.company.content.line_2') }
          <sup>®</sup>
          { t('about.company.content.line_3') }
        </Text>
      </Box>

      {/* 中国糖组学转化医学先行者 */}
      <Box className="section">
        <Box textAlign="center">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            { t('about.company.thePioneer.title') }
          </Text>
        </Box>
        <Box bgColor="#f8f9fa" p="1.5rem" className="boss" mt="8rem" mb="10rem">
          <Box className="wrap">
            <Box fontSize="1.125rem" color="#666" w={small ? "auto" : "556px"} className="info">
              <Text m="1.5rem 0" color="#4878F0" fontWeight="600" fontSize="2rem">
                { t('about.company.thePioneer.infos.line_1') }
              </Text>
              <Text fontWeight="500" lineHeight="2">
                { t('about.company.thePioneer.infos.line_2') }
                <br />
                { t('about.company.thePioneer.infos.line_3') }
              </Text>
              <Text lineHeight="2">
                { t('about.company.thePioneer.infos.line_4') }
              </Text>
            </Box>
            <Box className="avatar" position={small ? "relative" : "absolute"} top={small ? "0" : "-8rem"} right="0">
              <Img src={ImageUrls.ab_user} w="464px" />
              <Flex
                textAlign="center"
                fontSize="1.75rem"
                color="#3d3d3d"
                justify="space-around"
                boxShadow="11px 10px 20px 0px #2B458712"
                bg="#ffff"
                position="absolute"
                bottom="-1.75rem"
                w="90%"
                left="3%"
                pb="1rem"
              >
                {[
                  { number: "50+", title: "about.company.number.first" },
                  { number: "60+", title: "about.company.number.second" },
                  { number: "N+", title: "about.company.number.third" },
                ].map((i) => (
                  <Box key={i.number}>
                    <Text>{i.number}</Text>
                    <Text fontSize="0.75rem" color="#4878F0">
                      { t(i.title) }
                    </Text>
                  </Box>
                ))}
              </Flex>
            </Box>
          </Box>
        </Box>
        <Box position="relative" pb="3rem">
          {!small && <Img src={ImageUrls.icon07} position="absolute" left="0" bottom="0" />}
          <Flex className="wrap" justify="space-between">
            {small ? null : <Box></Box>}
            <Box maxW="580px" fontSize="1.125rem" color="#666" lineHeight="2">
              <Text fontWeight="600">{ t('about.company.member.academic.title') }</Text>
              { t('about.company.member.academic.content') }
              <Text fontWeight="600">{ t('about.company.member.scientific.title') }</Text>
              { t('about.company.member.scientific.content') }
            </Box>
          </Flex>
        </Box>

        <Box p="4rem 0" bg="#f8f9fa">
          <Flex className="wrap" justify="space-between">
            <Img src={ImageUrls.ab_01} position="absolute" right="0" bottom="-4rem" opacity={small ? 0.24 : 1} />
            <Box maxW="585px">
              <Text fontSize="1.625rem" color="#000" mb="1rem">
                {t('about.company.key_profile.title')}
              </Text>
              <Text fontSize="1.125rem" color="#666" lineHeight="2">
                {t('about.company.key_profile.line_1')}
                <br />
                <br />
                {t('about.company.key_profile.line_2')}
                <sup>®</sup>
                {t('about.company.key_profile.line_3')}
              </Text>
            </Box>
          </Flex>
        </Box>

        <Box position="relative" p="3rem 0" bg="#fff">
          {!small && <Img src={ImageUrls.icon07} position="absolute" left="0" bottom="0" />}
          <Flex className="wrap" justify="space-between">
            {small ? null : <Box></Box>}
            <Box maxW="585px">
              <Text fontSize="1.625rem" color="#000" mb="1rem">
                {t('about.company.result.title')}
              </Text>
              <Text fontSize="1.125rem" color="#666" lineHeight="2">
                {t('about.company.result.line_1')}
                <br />
                <br />
                {t('about.company.result.line_2')}
              </Text>
            </Box>
          </Flex>
        </Box>

        <Box p="4rem 0" bg="#f8f9fa">
          <Flex className="wrap" justify="space-between">
            <Img src={ImageUrls.ab_02} position="absolute" right="0" top="-4rem" opacity={small ? 0.24 : 1} />
            <Box maxW="585px">
              <Text fontSize="1.625rem" color="#000" mb="1rem">
                {t('about.company.awards.title')}
              </Text>
              <Text fontSize="1.125rem" color="#666" lineHeight="2">
                {t('about.company.awards.line_1')}
                <br />
                <br />
                {t('about.company.awards.line_2')}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default Company;
