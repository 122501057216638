import styled from "styled-components";

export const AboutNav = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: auto;
  background: #eff6ff;
  translate: 0 -2.5rem;
  .item {
    width: 33.3333333%;
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
    color: #333;
    cursor: pointer;
    &.active {
      background: #4878f0;
      color: white;
    }
  }
`;

export const MissionBox = styled.div`
  margin-bottom: 2.5rem;
  .box {
    width: 100%;
    justify-content: space-between;
    box-shadow: 0px 4px 20px 0px #00000014;
    min-height: 308px;

    .icon {
      max-width: 505px;
      width: 100%;
      background: ${(props) => props.color || "#6cafff"};
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      padding: 2rem 0;
    }
    .slogan {
      width: 100%;
      max-width: 587px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem 0;
    }
  }
`;
