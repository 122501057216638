import { service } from "@/service/request";
import { Box, Text } from "@chakra-ui/layout";
import { Link } from "@chakra-ui/react";
import moment from "antd/node_modules/moment";
import { useEffect, useState } from "react";
import { Link as ReachLink, useLocation } from "react-router-dom";
import i18n from "../../react-i18next-config";
import "./style.css";

const Article: React.FC = () => {
  const scrollToTop = () => {
    const sTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (sTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, sTop - sTop / 8);
    }
  };

  const [data, setData] = useState([] as any);
  const { search } = useLocation();
  const lang: string = i18n.language === 'zh-CN' ? 'zh-CN' : 'en';

  useEffect(() => {
    if (search) {
      const t = search?.split("=")[1];
      (async () => {
        const { data = {} } = (await service.get(
          `/api/articles/${t}?sort=publishedAt:desc&populate=cover&populate=localizations&&pagination[page]=1&pagination[pageSize]=9&locale=${lang}`
          )        ) as any;

        setData({
          id: data.id,
          ...data.attributes,
          updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
      })();
    }
  }, [lang, search]);

  return (
    <Box className="Article">
      <Box maxW="960px" p="3rem 1.5rem" m="auto">
        <Box className="nav" mb="3rem">
          <Link as={ReachLink} to={`/news`} onClick={scrollToTop}>
            <Text color="#4878f0" display="inline-block" cursor="pointer" mr="0.5rem">
              新闻活动
            </Text>
          </Link>
          / {data.title}
        </Box>
        <Box textAlign="center" mb="3rem">
          <Text color="#333" fontSize="2rem" mb="1rem">
            {data.title}
          </Text>
          <Text color="#666" fontSize="1rem">
            更新时间：{data.updatedAt}
          </Text>
        </Box>
        <Box className="content" fontSize="1.125rem" color="#666" lineHeight="1.8" minH="42vh">
          <div dangerouslySetInnerHTML={{ __html: data.content }} className="article_content"></div>
        </Box>
      </Box>
    </Box>
  );
};

export default Article;
