import { ImageUrls } from "@/asset/icon";
import styled from "styled-components";

export const ProductNav = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: auto;
  background: #eff6ff;
  translate: 0 -2rem;
  .item {
    width: 33.333333%;
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
    color: #333;
    cursor: pointer;
    &.active {
      background: #4878f0;
      color: white;
    }
  }
`;

export const PlatformAdvantageWrap = styled.div`
  background: url(${ImageUrls.g_bg});
  background-size: cover;
  position: relative;
`;
