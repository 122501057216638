import { usePageSize } from "@/App";
import { ImageUrls } from "@/asset/icon";
import { MissionBox } from "@/views/about/style";
import { Box, Flex, Img, Text } from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
/** 使命愿景 */
const Mission: React.FC = () => {
  const pageSize = usePageSize();

  const {t} = useTranslation()
  return (
    <Box className={`${pageSize} Mission`}>
      <Box className="wrap" textAlign="center" pt="3rem">
        <Box className="title">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            { t('about.mission.vision.title') }
          </Text>
        </Box>
      </Box>

      <Box className="wrap" p="5rem 1.5rem">
        <MissionBox color="#6cafff">
          <Flex className="box" flexWrap="wrap">
            <Flex className="icon">
              <Img src={ImageUrls.m_01} maxW="80px" w="100%" mb="0.5rem" />
              <Text fontSize="2rem" color="white">
                { t('about.mission.vision.title') }
              </Text>
            </Flex>

            <Flex className="slogan">
              <Text fontSize="2rem" color="#000" fontWeight="400" mb="1rem">
                { t('about.mission.vision.line_1') }
                <br />
                { t('about.mission.vision.line_2') }
              </Text>
            </Flex>
          </Flex>
        </MissionBox>

        <MissionBox color="#5dd4ee">
          <Flex className="box reverse" flexWrap="wrap">
            <Flex className="slogan">
              <Text fontSize="2rem" color="#000" fontWeight="400" mb="1rem">
                { t('about.mission.mission.line_1') }
                <br />
                { t('about.mission.mission.line_2') }
              </Text>
            </Flex>
            <Flex className="icon">
              <Img src={ImageUrls.m_02} maxW="80px" w="100%" mb="0.5rem" />
              <Text fontSize="2rem" color="white">
                { t('about.mission.mission.title') }
              </Text>
            </Flex>
          </Flex>
        </MissionBox>

        <MissionBox color="#5667ff">
          <Flex className="box" flexWrap="wrap">
            <Flex className="icon">
              <Img src={ImageUrls.m_03} maxW="80px" w="100%" mb="0.5rem" />
              <Text fontSize="2rem" color="white">
                { t('about.mission.value.title') }
              </Text>
            </Flex>

            <Flex className="slogan">
              <Text fontSize="2rem" color="#000" fontWeight="400" mb="1rem">
                { t('about.mission.value.content') }
              </Text>
            </Flex>
          </Flex>
        </MissionBox>
      </Box>
    </Box>
  );
};

export default Mission;
