import {usePageSize} from "@/App";
import {ImageUrls} from "@/asset/icon";
import {BaseUrl} from "@/constant";
import {service} from "@/service/request";
import {ClockCircleOutlined} from "@ant-design/icons";
import {Box, Flex, Text} from "@chakra-ui/layout";
import {Image, Link} from "@chakra-ui/react";
import {Divider, Pagination, PaginationProps} from "antd";
import moment from "antd/node_modules/moment";
import {useEffect, useState} from "react";
import {Link as ReachLink} from "react-router-dom";
import i18n from "../../react-i18next-config";
import "./style.css";

const News: React.FC = () => {
  const ps = usePageSize();
  const small = ps === "xs";

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [total, setTotal] = useState(0);

  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(true);

  const lang: string = i18n.language === 'zh-CN' ? 'zh-CN' : 'en';

  const scrollToTop = () => {
    const sTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (sTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, sTop - sTop / 8);
    }
  };

  const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
    if (type === "prev") {
      return <span style={{border: "1px solid #d9d9d9", padding: "0.5rem 1rem", borderRadius: "4px"}}>上一页</span>;
    }
    if (type === "next") {
      return <span style={{border: "1px solid #d9d9d9", padding: "0.5rem 1rem", borderRadius: "4px"}}>下一页</span>;
    }
    return originalElement;
  };

  useEffect(() => {
    (async () => {
      const {meta = {}, data = [{}]} = (await service.get(
        `/api/articles?sort=publishedAt:desc&populate=cover&&pagination[page]=${page}&pagination[pageSize]=${pageSize}&locale=${lang}`
      )) as any;
      setTotal(meta.pagination.total);
      setData(
        data.map((i: any) => {
          console.log(i.attributes.cover.data.attributes.url);
          return {
            id: i.id,
            title: i.attributes.title,
            abstract: i.attributes.abstract,
            thumb: `${BaseUrl}${i.attributes.cover.data.attributes.url}`,
            date: i.attributes?.date || moment().format("YYYY-MM-DD"),
          };
        })
      );
    })();
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [lang, page, pageSize]);

  return (
    <Box className="News">
      <Box className="banner" position="relative">
        {!small && (
          <>
            <Box
              className="card"
              p="2rem"
              mb="1.2rem"
              position="absolute"
              top="4rem"
              left="50%"
              w={lang === 'zh-CN' ? "26rem" : "27rem" }
              color="#fff"
              ml="-13rem"
            >
              <Box position="relative" zIndex="9" textAlign="center">
                <Text fontSize="2.25rem">News activities </Text>
                {
                  lang === 'zh-CN'
                    ?
                    <>
                      <Text fontSize="2.25rem">新闻活动</Text>
                    </>
                    :
                    <></>
                }
                <Box textAlign="center" m="0.5rem 0">
                  <Divider type="vertical" style={{width: "2px", height: "4rem", backgroundColor: "white"}}></Divider>
                </Box>
                <Text fontSize="1.25rem">
                  {
                    lang === 'en'
                      ?
                      <>
                        Wedeliver morenewsandactivities for you toenableyoutounderstandourenterprise more
                        comprehensively.

                      </>
                      :
                      <>
                        我们为您传递更多的新闻活动使您更加全方位的了解我们企业
                      </>
                  }
                </Text>
              </Box>
            </Box>
          </>
        )}
        <Image src={ImageUrls.news_banner} htmlWidth="100%"/>
      </Box>

      <Box className="wrap product" pb="3rem" pt="3rem">
        <Flex flexWrap="wrap">
          {
            loading
              ?
              <Flex alignItems="center" justifyContent="center" width="100%" height="150px">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                  <circle cx="12" cy="2" r="0" fill="#0284c7">
                    <animate attributeName="r" begin="0" calcMode="spline" dur="1s"
                             keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite"
                             values="0;2;0;0"></animate>
                  </circle>
                  <circle cx="12" cy="2" r="0" fill="#0284c7" transform="rotate(45 12 12)">
                    <animate attributeName="r" begin="0.125s" calcMode="spline" dur="1s"
                             keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite"
                             values="0;2;0;0"></animate>
                  </circle>
                  <circle cx="12" cy="2" r="0" fill="#0284c7" transform="rotate(90 12 12)">
                    <animate attributeName="r" begin="0.25s" calcMode="spline" dur="1s"
                             keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite"
                             values="0;2;0;0"></animate>
                  </circle>
                  <circle cx="12" cy="2" r="0" fill="#0284c7" transform="rotate(135 12 12)">
                    <animate attributeName="r" begin="0.375s" calcMode="spline" dur="1s"
                             keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite"
                             values="0;2;0;0"></animate>
                  </circle>
                  <circle cx="12" cy="2" r="0" fill="#0284c7" transform="rotate(180 12 12)">
                    <animate attributeName="r" begin="0.5s" calcMode="spline" dur="1s"
                             keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite"
                             values="0;2;0;0"></animate>
                  </circle>
                  <circle cx="12" cy="2" r="0" fill="#0284c7" transform="rotate(225 12 12)">
                    <animate attributeName="r" begin="0.625s" calcMode="spline" dur="1s"
                             keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite"
                             values="0;2;0;0"></animate>
                  </circle>
                  <circle cx="12" cy="2" r="0" fill="#0284c7" transform="rotate(270 12 12)">
                    <animate attributeName="r" begin="0.75s" calcMode="spline" dur="1s"
                             keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite"
                             values="0;2;0;0"></animate>
                  </circle>
                  <circle cx="12" cy="2" r="0" fill="#0284c7" transform="rotate(315 12 12)">
                    <animate attributeName="r" begin="0.875s" calcMode="spline" dur="1s"
                             keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite"
                             values="0;2;0;0"></animate>
                  </circle>
                </svg>
              </Flex>
              :
              data.length > 0 && data.map((n: any) => {
                return (
                  <Box className="product-card" mb="2rem" w={small ? "100%" : "33.333%"} key={n.id}>
                    <Box
                      className="p-thumb"
                      bg="white"
                      boxShadow="0 0 24px 8px #00000012"
                      borderRadius="4px"
                      m={small ? "0" : "0 1rem"}
                    >
                      <div
                        className="imgBox"
                        style={{
                          width: "100%",
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <div className="content" style={{paddingBottom: "56.6%"}}>
                          <Image src={n.thumb} m="0" style={{position: "absolute", left: 0, top: 0}}/>
                        </div>
                      </div>
                      <Box p="1rem">
                        <Text
                          fontSize="1.25rem"
                          fontWeight="400"
                          color="#414040"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="nowrap"
                        >
                          {n?.title}
                        </Text>
                        <Text
                          fontSize="0.875rem"
                          color="#999"
                          mb="0.75rem"
                          overflow="hidden"
                          lineHeight="1.5"
                          height="63px"
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {n?.abstract}
                        </Text>
                        <Flex className="btn" justifyContent="space-between" alignItems="center">
                          <Text fontSize="0.875rem" lineHeight="1" color="#4878F0">
                            <ClockCircleOutlined style={{marginRight: "0.25rem"}}/>
                            {n?.date}
                          </Text>
                          <Link as={ReachLink} to={`/news/article?id=${n.id}`} onClick={scrollToTop}>
                            <Text
                              p="0.5rem 1.5rem"
                              color="#414040"
                              borderRadius="4px"
                              bg="#d6d6d6"
                              display="inline-block"
                              cursor="pointer"
                              fontSize="0.875rem"
                            >
                              查看详情
                            </Text>
                          </Link>
                        </Flex>
                      </Box>
                    </Box>
                  </Box>
                );
              })
          }
        </Flex>

        <Box textAlign="center">
          <Pagination
            total={total}
            pageSize={pageSize || 0}
            current={page}
            itemRender={itemRender}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default News;
