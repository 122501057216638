import { usePageSize } from "@/App";
import { ImageUrls } from "@/asset/icon";
import { service } from "@/service/request";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Image, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link as ReachLink } from "react-router-dom";
import "./style.css";

const Join: React.FC = () => {
  const ps = usePageSize();
  const small = ps === "xs";
  const scrollToTop = () => {
    const sTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (sTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, sTop - sTop / 8);
    }
  };

  const [jobList, setJobList] = useState([] as any);

  useEffect(() => {
    (async () => {
      const { data = [{}] } = (await service.get("/api/jobs")) as any;
      setJobList(
        data.map((i: any) => {
          return {
            id: i.id,
            job_name: i.attributes.job_name,
            job_name_en: i.attributes.job_name_en,
            location: i.attributes.location,
          };
        })
      );
    })();
  }, []);

  return (
    <Box className="Join">
      <Box className="banner" position="relative">
        <Image src={ImageUrls.join} htmlWidth="100%" />
      </Box>
      <Box position="relative">
        <Image src={ImageUrls.join_bg} position="absolute" left="0" bottom="0" top={small ? "" : "0"} />
        <Box p="3rem 1.5rem 6rem" position="relative" maxW="960px" m="auto">
          <Box bg="white" textAlign="center" position="absolute" top="-5rem" p="1.5rem 3rem">
            <Text color="#4878f0" fontSize="1rem">
              SysDiagno Biotech Job List
            </Text>
            <Text color="#414040" fontSize="2rem">
              岗位列表
            </Text>
          </Box>
          {jobList.map((j: any) => (
            <Box
              className="iBox"
              _hover={{ ".j-item": { transform: `translateX(${small ? "-150px" : "-200px"})` } }}
              key={j.id}
              boxShadow="0 0 24px 8px #00000012"
              borderRadius="4px"
              position="relative"
            >
              <Flex
                bgSize="cover"
                justifyContent="space-between"
                alignItems="center"
                m="1.75rem 0"
                p="2.5rem"
                className="j-item"
              >
                <Box
                  bgImg={`url(${ImageUrls.join_item_bg})`}
                  position="absolute"
                  left="0"
                  top="0"
                  w="100%"
                  bottom="0"
                  className="hoverBg"
                />
                <Box className="title">
                  <Text fontSize="1.5rem" color="#111" mb="0.25rem" className="t-ch">
                    {j.job_name}
                  </Text>
                  <Text fontSize="1.25rem" color="#bac0cd" className="t-en">
                    {j.job_name_en}
                  </Text>
                </Box>

                <Flex alignItems="center">
                  <Text mr="1.5rem" fontSize="1.5rem" color="#A0A8B7">
                    {j.location}
                  </Text>
                  <Image src={ImageUrls.join_01} w="3rem" />
                </Flex>
              </Flex>

              <Link as={ReachLink} to={`/join/job?id=${j.id}`} onClick={scrollToTop}>
                <Flex className="more">
                  <Text mr="1rem" fontSize="1.25rem">
                    查看详情
                  </Text>
                  <Image src={ImageUrls.arrow} h="0.75rem" />
                </Flex>
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Join;
