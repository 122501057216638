// Layout.tsx
import { usePageSize } from "@/App";
import { Box } from "@chakra-ui/layout";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

export default function Layout({ children }: Props) {
  const pageSize = usePageSize();
  return (
    <Box h="100vh" className={pageSize} w="100%">
      {children}
    </Box>
  );
}
