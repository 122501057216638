import {usePageSize} from "@/App";
import {ImageUrls} from "@/asset/icon";
import Company from "@/views/about/company";
import Mission from "@/views/about/mission";
import Result from "@/views/about/result";
import {AboutNav} from "@/views/about/style";
import Team from "@/views/about/team";
import {Box, Flex, Image, Text} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {useTranslation} from "react-i18next";
import "./style.css";

const NavList = [
  {title: "about.index.company", key: "company"},
  {title: "about.index.result", key: "result"},
  {title: "about.index.team", key: "team"},
  // { title: "发展历程", key: "progress" },
  {title: "about.index.mission", key: "mission"},
];

const About: React.FC = () => {
  const [key, setKey] = useState<string>("company");
  const {search} = useLocation();
  const history = useHistory();

  const {t} = useTranslation()

  useEffect(() => {
    console.log("关于");
    if (search) {
      const t = search?.split("=")[1];
      setKey(t);
    }
  }, [search]);

  const pageSize = usePageSize();
  return (
    <Box className={`${pageSize} About`} w="100%">
      <Box w="100%">
        <Image src={ImageUrls.about_banner} htmlWidth="100%"/>
      </Box>
      <AboutNav>
        <Flex justify="space-between">
          {NavList.map((nav) => (
            <Flex
              className={`item ${key === nav.key ? "active" : ""}`}
              key={nav.key}
              justifyContent="center"
              alignItems="center"
              onClick={() => {
                history.push(`/about?tab=${nav.key}`);
              }}
            >
              <Text> {t(nav.title)}</Text>
            </Flex>
          ))}
        </Flex>
      </AboutNav>
      <Box className="main">
        {key === "company" && <Company/>}
        {key === "mission" && <Mission/>}
        {key === "result" && <Result/>}
        {key === "team" && <Team/>}
        {/* {key === "progress" && <Progress />} */}
      </Box>
    </Box>
  );
};

export default About;
