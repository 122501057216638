import {usePageSize} from "@/App";
import {ImageUrls} from "@/asset/icon";
import {FooterGroup} from "@/components/footer/styled";
import {Box, Flex, HStack, Image, Link, Text} from "@chakra-ui/react";
import {Link as ReachLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18n from "../../react-i18next-config";

export const FooterBar = () => {
  const pageSize = usePageSize();
  const isSmall = pageSize === "xs";

  const scrollToTop = () => {
    const sTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (sTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, sTop - sTop / 8);
    }
  };

  const {t} = useTranslation()
  const lang = i18n.language

  return (
    <Box color="white" position="relative" bgColor="#111">
      <Box>
        {isSmall ? (
          <Flex p="1rem" alignItems="center">
            <Text>Copyright 2023 BRIX Templates | All Rights Reserved | Terms and Conditions | Privacy Policy</Text>
            <Box ml="2rem" w="72px" flexShrink={0}>
              <Image src={ImageUrls.qrCode} mt="1rem" w="72px" mb="0.5rem"/>
              <Text textAlign="center" fontSize="0.875rem" color="white">
                {t('footer.nav.weChat')}
              </Text>
            </Box>
          </Flex>
        ) : (
          <Box className="wrap" zIndex={100}>
            <Flex justify="space-between" p="7.5rem 1.5rem 6rem">
              <Box width="350px">
                <Image src={ImageUrls.logo} w={100} mb="1.5rem"/>

                <Text mb="1.5rem">
                  {t('footer.desc.line_1')}
                </Text>
                <Text>
                  {t('footer.desc.line_2')}
                  <sup>®</sup>
                  {t('footer.desc.line_3')}
                  <sup>®</sup>
                  {t('footer.desc.line_4')}
                </Text>
              </Box>

              <HStack spacing={lang === 'zh-CN' ? "1.5rem" : "2rem"} alignItems="flex-start">
                <FooterGroup>
                  <Link as={ReachLink} to="/home" className="title" onClick={scrollToTop}>
                    {t('footer.nav.index')}
                  </Link>
                </FooterGroup>
                <FooterGroup>
                  <Link as={ReachLink} to="/about" className="title" onClick={scrollToTop}>
                    {t('footer.nav.about.us')}
                  </Link>
                  <Link as={ReachLink} to="/about?tab=company" className="item" onClick={scrollToTop}>
                    {t('footer.nav.about.sysDiagno')}
                  </Link>
                  <Link as={ReachLink} to="/about?tab=result" className="item" onClick={scrollToTop}>
                    {t('footer.nav.about.research')}
                  </Link>
                  <Link as={ReachLink} to="/about?tab=team" className="item" onClick={scrollToTop}>
                    {t('footer.nav.about.team')}
                  </Link>
                  <Link as={ReachLink} to="/about?tab=mission" className="item" onClick={scrollToTop}>
                    {t('footer.nav.about.mission')}
                  </Link>
                </FooterGroup>
                <FooterGroup>
                  <Link as={ReachLink} to="/product" className="title" onClick={scrollToTop}>
                    {t('footer.nav.platforms.index')}
                  </Link>
                  <Link as={ReachLink} to="/product?tab=platform" className="item" onClick={scrollToTop}>
                    {t('footer.nav.platforms.glyFace')}
                    <sup>®</sup>
                  </Link>

                  <Link as={ReachLink} to="/product?tab=diagnosis" className="item" onClick={scrollToTop}>
                    {t('footer.nav.platforms.diseaseDiagnosis')}
                  </Link>
                  <Link as={ReachLink} to="/product?tab=estimate" className="item" onClick={scrollToTop}>
                    {t('footer.nav.platforms.healthAssessment')}
                  </Link>
                </FooterGroup>

                <FooterGroup>
                  <Link as={ReachLink} to="/project" className="title" onClick={scrollToTop}>
                    {t('footer.nav.projects')}
                  </Link>
                </FooterGroup>

                {
                  lang === 'zh-CN'
                    ?
                    <FooterGroup>
                      <Link as={ReachLink} to="/news" className="title" onClick={scrollToTop}>
                        {t('footer.nav.news')}
                      </Link>
                    </FooterGroup>
                    :
                    <></>
                }
                <FooterGroup>
                  <Link as={ReachLink} to="/contact" className="title" onClick={scrollToTop}>
                    {t('footer.nav.contact')}
                  </Link>
                </FooterGroup>

                {
                  lang === 'zh-CN'
                    ?
                    <FooterGroup>
                      <Link as={ReachLink} to="/join" className="title" onClick={scrollToTop}>
                        {t('footer.nav.join')}
                      </Link>
                    </FooterGroup>
                    :
                    <></>
                }
                <Box>
                  <Image src={ImageUrls.qrCode} mt="1rem" w="100px" mb="0.5rem"/>
                  <Text textAlign="center" fontSize="0.875rem" color="white">
                    {t('footer.nav.weChat')}
                  </Text>
                </Box>
              </HStack>
            </Flex>
          </Box>
        )}
        {!isSmall &&
          <Image src={ImageUrls.icon04} position="absolute" bottom="0" top="0" right="0" pointerEvents="none"/>
        }
      </Box>
      {!isSmall && (
        <Text textAlign="center" color="#666" p="1rem" bg="#232323">
          Copyright 2023 BRIX Templates | All Rights Reserved | Terms and Conditions | Privacy Policy
          <a
            target="_blank"
            rel="noreferrer"
            href="http://www.beian.gov.cn/portal/registerSystemInfo"
            style={{display: "flex", justifyContent: "center", marginTop: "12px"}}
          >
            <Image src={ImageUrls.gh} w="20px" mr="12px"/>
            <span>苏公网安备 32011202000839号</span>
          </a>
        </Text>
      )}
    </Box>
  );
};
