import {ImageUrls} from "@/asset/icon";
import {NavWrap} from "@/components/nav/styled";
import {Routers} from "@/routers";
import {MenuOutlined} from "@ant-design/icons";
import {Box, Center, Flex, HStack, Img, Link} from "@chakra-ui/react";
import React, {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router";
import {Link as ReachLink} from "react-router-dom";
import i18n from "../../react-i18next-config";

export const Nav = ({pageSize, t, changeLanguage, language}: any) => {
  const size = useMemo(() => pageSize, [pageSize]);
  const isSmall = size === "xs";
  const [v, setV] = useState<boolean>(false);
  const {pathname} = useLocation();
  console.log("🚀 ~ file: index.tsx:15 ~ pathname:", pathname);

  const [key, setKey] = useState<string>("/home");
  console.log("🚀 ~ file: index.tsx:18 ~ key:", key);
  const scrollToTop = () => {
    const sTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (sTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, sTop - sTop / 8);
    }
  };

  useEffect(() => {
    setKey(pathname);
  }, [pathname]);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  const [locale, setLocale] =
    useState(i18n.language === 'zh-CN' ? '中文' : 'EN')
  const onChange = async (lang: string) => {
    if (lang === 'en-US') {
      setLocale('EN')
    } else if (lang === 'zh-CN') {
      setLocale('中文')
    }
    await changeLanguage(lang)

    if(isSmall)
      setV(false)
  }

  const show = (display?: boolean, lang?: boolean) => {
    if (language === 'en-US') {
      if (display && lang) return true
    } else if (language === 'zh-CN') {
      if (display) return true
    }
  }

  const [select, setSelect] = useState(false)
  const onMouseEnterList = () => {
    setSelect(true);
  };

  const onMouseLeaveList = () => {
    setSelect(false);
  };

  return (
    <NavWrap>
      {isSmall ? (
        <Box>
          <Flex
            position="fixed"
            justifyContent="space-between"
            p="1rem"
            w="100%"
            zIndex="99999"
            bg="white"
            boxShadow="0 0 16px 4px #00000012"
          >
            <Link
              as={ReachLink}
              to="/home"
              className="link"
              color="#333"
              onClick={() => {
                setV(false);
                scrollToTop();
              }}
            >
              <Img src={ImageUrls.logo} h="2rem"/>
            </Link>
            <Center>
              <MenuOutlined
                onClick={() => {
                  setV(!v);
                }}
              ></MenuOutlined>
            </Center>
            <Flex
              style={{display: v ? "flex" : "none"}}
              position="absolute"
              top="3rem"
              right="0"
              flexDirection="column"
              bgColor="#fff"
              p="1rem"
              boxShadow="0px 2px 4px 0px #0000001f"
              zIndex="999"
              transition="all .5s"
              width="50%"
              textAlign="center"
            >
              {Routers.filter((ro) => show(ro.showNav, ro.showEn)).map((r) => (
                <Link
                  as={ReachLink}
                  to={r.path}
                  key={r.path}
                  color="#333"
                  mb="1.25rem"
                  fontSize="2xl"
                  onClick={() => {
                    setV(false);
                    scrollToTop();
                  }}
                >
                  {t(r.trans_text)}
                </Link>
              ))}
              <Box className={`link mobile-lang`}>
                <Box
                  className={`kcPPls ${isHovered && !select ? 'active' : ''} `}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.01602 16.8787C4.70059 16.8787 1.18848 13.3666 1.18848 9.05117C1.18848 4.73574 4.70059 1.22363 9.01602 1.22363C10.7246 1.22363 12.3488 1.76504 13.7111 2.78809C13.9766 2.98848 14.0311 3.36465 13.8307 3.63184C13.6303 3.89727 13.2541 3.95176 12.9869 3.75137C11.8338 2.88652 10.4609 2.42949 9.01426 2.42949C5.36152 2.42949 2.39082 5.4002 2.39082 9.05293C2.39082 12.7057 5.36152 15.6764 9.01426 15.6764C12.667 15.6764 15.6377 12.7057 15.6377 9.05293C15.6377 8.20039 15.4777 7.36895 15.1631 6.58496C15.0383 6.27559 15.1895 5.92578 15.4971 5.80098C15.8064 5.67617 16.1563 5.82734 16.2811 6.13496C16.6537 7.06309 16.8436 8.04395 16.8436 9.05117C16.8436 13.3684 13.3332 16.8787 9.01602 16.8787Z"
                      fill="currentColor"></path>
                    <path
                      d="M13.8799 4.80371C13.8799 4.98786 13.953 5.16447 14.0833 5.29468C14.2135 5.42489 14.3901 5.49805 14.5742 5.49805C14.7584 5.49805 14.935 5.42489 15.0652 5.29468C15.1954 5.16447 15.2686 4.98786 15.2686 4.80371C15.2686 4.61956 15.1954 4.44295 15.0652 4.31274C14.935 4.18253 14.7584 4.10938 14.5742 4.10938C14.3901 4.10938 14.2135 4.18253 14.0833 4.31274C13.953 4.44295 13.8799 4.61956 13.8799 4.80371Z"
                      fill="currentColor"></path>
                    <path
                      d="M9.20391 16.7765C9.09316 16.7765 8.98242 16.7379 8.89102 16.6605C8.60801 16.4197 8.33555 16.1578 8.08066 15.8818C7.28086 15.0222 6.6498 14.0379 6.20332 12.955C5.72168 11.7896 5.47559 10.5556 5.4668 9.28473V9.2232C5.4668 8.14039 5.64434 7.07516 5.99062 6.05914C6.49687 4.58082 7.36523 3.22555 8.5043 2.14274C8.59043 2.06012 8.68008 1.97926 8.76973 1.90016C8.96836 1.72438 9.27422 1.74195 9.45 1.94234C9.62578 2.14098 9.6082 2.44684 9.40781 2.62262C9.32695 2.69469 9.24609 2.76852 9.16875 2.84234C8.14394 3.81969 7.35996 5.03961 6.90293 6.37203C6.59004 7.28785 6.43008 8.24762 6.43008 9.22496V9.28297C6.43711 10.4255 6.66035 11.5382 7.09277 12.5877C7.49531 13.5632 8.06484 14.4509 8.78555 15.2261C9.01582 15.4757 9.26191 15.7113 9.51504 15.9275C9.71719 16.0998 9.7418 16.4039 9.56777 16.606C9.47637 16.7185 9.34101 16.7765 9.20391 16.7765Z"
                      fill="currentColor"></path>
                    <path
                      d="M8.56791 16.7539C8.42026 16.7539 8.27612 16.6871 8.1812 16.5605C8.02123 16.3478 8.06518 16.0455 8.27963 15.8855C8.56088 15.6763 8.83159 15.446 9.08647 15.2052C9.85639 14.4722 10.4804 13.6197 10.941 12.6687C11.4613 11.6 11.7548 10.4521 11.8128 9.26031C11.8199 9.11089 11.8234 8.96324 11.8234 8.82085C11.8234 8.05796 11.7267 7.29859 11.5333 6.5691C11.1677 5.17691 10.4523 3.87964 9.46791 2.81968C9.3976 2.74234 9.32202 2.66499 9.23588 2.57886C9.0478 2.39077 9.04604 2.08667 9.23412 1.89683C9.42221 1.70874 9.72631 1.70699 9.91616 1.89507C10.0146 1.99175 10.099 2.0814 10.1781 2.16753C11.2679 3.33999 12.0607 4.77788 12.4667 6.32476C12.6794 7.13511 12.7884 7.97534 12.7884 8.82261C12.7884 8.98081 12.7849 9.14429 12.7761 9.30777C12.7111 10.6314 12.3859 11.9041 11.8076 13.0906C11.296 14.1453 10.6035 15.091 9.75092 15.9031C9.46967 16.172 9.16909 16.4251 8.8562 16.6572C8.7683 16.7222 8.66811 16.7539 8.56791 16.7539Z"
                      fill="currentColor"></path>
                    <path
                      d="M14.6756 13.1324C14.5824 13.1324 14.4875 13.1061 14.4049 13.048C13.3836 12.3502 12.2586 11.8545 11.0615 11.5732C10.3127 11.3975 9.53926 11.3078 8.7623 11.3078H8.75879C8.1752 11.3078 7.59336 11.357 7.02559 11.4572C5.74238 11.6805 4.52949 12.148 3.42383 12.8441C3.19883 12.9865 2.90176 12.918 2.75937 12.693C2.61699 12.468 2.68555 12.1709 2.91055 12.0285C4.12344 11.2656 5.45234 10.7541 6.86035 10.508C7.48262 10.399 8.1207 10.3445 8.75879 10.3445H8.7623C9.61309 10.3445 10.4604 10.443 11.2812 10.6363C12.5943 10.9439 13.8283 11.4889 14.948 12.2535C15.1678 12.4029 15.224 12.7035 15.0746 12.9232C14.9797 13.0586 14.8285 13.1324 14.6756 13.1324ZM16.2418 8.44609L2.14766 8.44082C1.88223 8.44082 1.66602 8.22461 1.66602 7.95918C1.66602 7.69375 1.88223 7.47754 2.14766 7.47754L16.2418 7.48281C16.5072 7.48281 16.7234 7.69902 16.7234 7.96445C16.7234 8.23164 16.5072 8.44609 16.2418 8.44609Z"
                      fill="currentColor"></path>
                  </svg>
                  {locale}
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.757689 4.00728C0.757689 3.85618 0.809553 3.70444 0.915513 3.58087C1.15113 3.30556 1.56504 3.27356 1.84003 3.50981L6.0026 7.07727L10.1652 3.50981C10.4402 3.27356 10.8541 3.30558 11.0897 3.58087C11.3243 3.85554 11.2933 4.26978 11.0186 4.50475L6.42933 8.43844C6.18379 8.64908 5.82141 8.64908 5.57588 8.43844L0.986577 4.50475C0.835467 4.37542 0.757689 4.19167 0.757689 4.00728Z"
                      fill="currentColor"></path>
                  </svg>
                  <Box
                    w="100%"
                    className={'hover-mobile'}
                    onMouseEnter={onMouseEnterList}
                    onMouseLeave={onMouseLeaveList}
                  >
                    {isHovered &&
                      <>
                        <Box
                          onClick={async () => {
                            await onChange('zh-CN')
                          }}
                        >
                          简体中文
                        </Box>
                        <Box
                          onClick={async () => {
                            await onChange('en-US')
                          }}
                        >
                          ENGLISH
                        </Box>
                      </>
                    }
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Flex>
          <Box h="4rem"></Box>
        </Box>
      ) : (
        <Box className="wrap">
          <Flex justify="space-between">
            <Center>
              <Link
                as={ReachLink}
                to="/home"
                color="#333"
                className="link"
                onClick={() => {
                  setV(false);
                  scrollToTop();
                }}
              >
                <Img src={ImageUrls.logo} h="2rem"/>
              </Link>
            </Center>
            <HStack spacing="20px">
              {Routers.filter((ro) => show(ro.showNav, ro.showEn)).map((r, index) => (
                <Link
                  className={`link ${r.path.includes(key) ? "active" : ""}`}
                  p={index === 0 ? "22px 8px" : "1.375rem 0.75rem "}
                  as={ReachLink}
                  to={r.path}
                  key={r.path}
                  fontSize="1.125rem"
                  fontWeight="400"
                  onClick={() => {
                    setKey(r.path);
                  }}
                >
                  {t(r.trans_text)}
                </Link>
              ))}
              <Box className={`link CfQPL`}>
                <Box
                  p="20px"
                  className={`kcPPls ${isHovered && !select ? 'active' : ''} `}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.01602 16.8787C4.70059 16.8787 1.18848 13.3666 1.18848 9.05117C1.18848 4.73574 4.70059 1.22363 9.01602 1.22363C10.7246 1.22363 12.3488 1.76504 13.7111 2.78809C13.9766 2.98848 14.0311 3.36465 13.8307 3.63184C13.6303 3.89727 13.2541 3.95176 12.9869 3.75137C11.8338 2.88652 10.4609 2.42949 9.01426 2.42949C5.36152 2.42949 2.39082 5.4002 2.39082 9.05293C2.39082 12.7057 5.36152 15.6764 9.01426 15.6764C12.667 15.6764 15.6377 12.7057 15.6377 9.05293C15.6377 8.20039 15.4777 7.36895 15.1631 6.58496C15.0383 6.27559 15.1895 5.92578 15.4971 5.80098C15.8064 5.67617 16.1563 5.82734 16.2811 6.13496C16.6537 7.06309 16.8436 8.04395 16.8436 9.05117C16.8436 13.3684 13.3332 16.8787 9.01602 16.8787Z"
                      fill="currentColor"></path>
                    <path
                      d="M13.8799 4.80371C13.8799 4.98786 13.953 5.16447 14.0833 5.29468C14.2135 5.42489 14.3901 5.49805 14.5742 5.49805C14.7584 5.49805 14.935 5.42489 15.0652 5.29468C15.1954 5.16447 15.2686 4.98786 15.2686 4.80371C15.2686 4.61956 15.1954 4.44295 15.0652 4.31274C14.935 4.18253 14.7584 4.10938 14.5742 4.10938C14.3901 4.10938 14.2135 4.18253 14.0833 4.31274C13.953 4.44295 13.8799 4.61956 13.8799 4.80371Z"
                      fill="currentColor"></path>
                    <path
                      d="M9.20391 16.7765C9.09316 16.7765 8.98242 16.7379 8.89102 16.6605C8.60801 16.4197 8.33555 16.1578 8.08066 15.8818C7.28086 15.0222 6.6498 14.0379 6.20332 12.955C5.72168 11.7896 5.47559 10.5556 5.4668 9.28473V9.2232C5.4668 8.14039 5.64434 7.07516 5.99062 6.05914C6.49687 4.58082 7.36523 3.22555 8.5043 2.14274C8.59043 2.06012 8.68008 1.97926 8.76973 1.90016C8.96836 1.72438 9.27422 1.74195 9.45 1.94234C9.62578 2.14098 9.6082 2.44684 9.40781 2.62262C9.32695 2.69469 9.24609 2.76852 9.16875 2.84234C8.14394 3.81969 7.35996 5.03961 6.90293 6.37203C6.59004 7.28785 6.43008 8.24762 6.43008 9.22496V9.28297C6.43711 10.4255 6.66035 11.5382 7.09277 12.5877C7.49531 13.5632 8.06484 14.4509 8.78555 15.2261C9.01582 15.4757 9.26191 15.7113 9.51504 15.9275C9.71719 16.0998 9.7418 16.4039 9.56777 16.606C9.47637 16.7185 9.34101 16.7765 9.20391 16.7765Z"
                      fill="currentColor"></path>
                    <path
                      d="M8.56791 16.7539C8.42026 16.7539 8.27612 16.6871 8.1812 16.5605C8.02123 16.3478 8.06518 16.0455 8.27963 15.8855C8.56088 15.6763 8.83159 15.446 9.08647 15.2052C9.85639 14.4722 10.4804 13.6197 10.941 12.6687C11.4613 11.6 11.7548 10.4521 11.8128 9.26031C11.8199 9.11089 11.8234 8.96324 11.8234 8.82085C11.8234 8.05796 11.7267 7.29859 11.5333 6.5691C11.1677 5.17691 10.4523 3.87964 9.46791 2.81968C9.3976 2.74234 9.32202 2.66499 9.23588 2.57886C9.0478 2.39077 9.04604 2.08667 9.23412 1.89683C9.42221 1.70874 9.72631 1.70699 9.91616 1.89507C10.0146 1.99175 10.099 2.0814 10.1781 2.16753C11.2679 3.33999 12.0607 4.77788 12.4667 6.32476C12.6794 7.13511 12.7884 7.97534 12.7884 8.82261C12.7884 8.98081 12.7849 9.14429 12.7761 9.30777C12.7111 10.6314 12.3859 11.9041 11.8076 13.0906C11.296 14.1453 10.6035 15.091 9.75092 15.9031C9.46967 16.172 9.16909 16.4251 8.8562 16.6572C8.7683 16.7222 8.66811 16.7539 8.56791 16.7539Z"
                      fill="currentColor"></path>
                    <path
                      d="M14.6756 13.1324C14.5824 13.1324 14.4875 13.1061 14.4049 13.048C13.3836 12.3502 12.2586 11.8545 11.0615 11.5732C10.3127 11.3975 9.53926 11.3078 8.7623 11.3078H8.75879C8.1752 11.3078 7.59336 11.357 7.02559 11.4572C5.74238 11.6805 4.52949 12.148 3.42383 12.8441C3.19883 12.9865 2.90176 12.918 2.75937 12.693C2.61699 12.468 2.68555 12.1709 2.91055 12.0285C4.12344 11.2656 5.45234 10.7541 6.86035 10.508C7.48262 10.399 8.1207 10.3445 8.75879 10.3445H8.7623C9.61309 10.3445 10.4604 10.443 11.2812 10.6363C12.5943 10.9439 13.8283 11.4889 14.948 12.2535C15.1678 12.4029 15.224 12.7035 15.0746 12.9232C14.9797 13.0586 14.8285 13.1324 14.6756 13.1324ZM16.2418 8.44609L2.14766 8.44082C1.88223 8.44082 1.66602 8.22461 1.66602 7.95918C1.66602 7.69375 1.88223 7.47754 2.14766 7.47754L16.2418 7.48281C16.5072 7.48281 16.7234 7.69902 16.7234 7.96445C16.7234 8.23164 16.5072 8.44609 16.2418 8.44609Z"
                      fill="currentColor"></path>
                  </svg>
                  {locale}
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.757689 4.00728C0.757689 3.85618 0.809553 3.70444 0.915513 3.58087C1.15113 3.30556 1.56504 3.27356 1.84003 3.50981L6.0026 7.07727L10.1652 3.50981C10.4402 3.27356 10.8541 3.30558 11.0897 3.58087C11.3243 3.85554 11.2933 4.26978 11.0186 4.50475L6.42933 8.43844C6.18379 8.64908 5.82141 8.64908 5.57588 8.43844L0.986577 4.50475C0.835467 4.37542 0.757689 4.19167 0.757689 4.00728Z"
                      fill="currentColor"></path>
                  </svg>
                  <Box
                    className={'hover'}
                    onMouseEnter={onMouseEnterList}
                    onMouseLeave={onMouseLeaveList}
                  >
                    {isHovered &&
                      <>
                        <Box
                          onClick={async () => {
                            await onChange('zh-CN')
                          }}
                        >
                          简体中文
                        </Box>
                        <Box
                          onClick={async () => {
                            await onChange('en-US')
                          }}
                        >
                          ENGLISH
                        </Box>
                      </>
                    }
                  </Box>
                </Box>
              </Box>
            </HStack>
          </Flex>
        </Box>
      )}
    </NavWrap>
  );
};
