import {usePageSize} from "@/App";
import {ImageUrls} from "@/asset/icon";
import {PlatformAdvantage, PlatformCardList} from "@/views/product/config";
import {PlatformAdvantageWrap} from "@/views/product/styled";
import {Box, Flex, Image, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import i18n from "../../react-i18next-config";

const Platform: React.FC = () => {
  const pageSize = usePageSize();
  const small = pageSize === "xs";

  const {t} = useTranslation()
  const lang = i18n.language

  return (
    <Box className={pageSize !== "sm" ? "1140px" : ""}>
      <Box textAlign="center" className="wrap" pb="4rem" pt="3rem">
        <Box mb="3rem">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('product.platform.part_1')}
            <sup>®</sup>
            &nbsp;{t('product.platform.part_2')}
          </Text>
        </Box>
        <Box fontSize="1.125rem" color="#666" lineHeight="3rem" mb="2rem">
          {t('product.platformGlyFace.line_1')}
          <sup>®</sup>
          &nbsp;{t('product.platformGlyFace.line_2')}
          {t('product.platformGlyFace.line_3')}
          <sup>®</sup>
          {t('product.platformGlyFace.line_4')}
        </Box>
        <Image src={ImageUrls.icon02} w="130px" h="auto" m="auto"/>
      </Box>

      <Box bgColor="#f8f9fa" p="4rem 0 4.5rem">
        <Flex className="wrap" flexWrap="wrap">
          {PlatformCardList.map((c) => {
            return (
              <Box className="item" key={c.index} w={small ? "50%" : "33.333%"}>
                <Flex p="1rem" bg="#fff" borderRadius="4px" m="0.5rem">
                  <Box w={ small ? "85%" : "260px"} >
                    <Text className="title" fontSize="1.5rem" fontWeight="600" color="#333" mb="0.5rem">
                      {t(c.title)}
                    </Text>
                    <Text
                      fontSize="1.125rem"
                      color="#999"
                      lineHeight="1.875rem"
                      mb="1rem"
                      h={small
                        ? lang === 'zh-CN'
                          ? "7.5rem;" : "26rem"
                        : lang === 'en-US'
                          ? "12.5rem;" : "5rem"
                      }
                      // h={lang === 'en-US' ? "12.5rem;" : "5rem"}
                    >
                      {t(c.info)}
                    </Text>
                    <Image src={ImageUrls[c.index]}/>
                  </Box>
                  <Image src={ImageUrls[c.icon]} m="auto" mt="1.5rem" w={small ? "32px" : "60px"}/>
                </Flex>
              </Box>
            );
          })}
        </Flex>
      </Box>

      <PlatformAdvantageWrap>
        <Image
          src={ImageUrls.icon08}
          fit="contain"
          position="absolute"
          bottom="0"
          right="0"
          opacity={small ? 0.32 : 1}
        />
        <Box color="white" w="100%" maxW="1142px" pt="5rem" pb="3rem" m="auto">
          <Box mb="3rem" textAlign="center">
            <Text color="#fff" fontSize="2rem" fontWeight="600">
              {t('product.advantages.title')}
            </Text>
          </Box>
          <Flex flexWrap="wrap" justifyContent="space-around" alignItems="center">
            {
              small ?
                <>
                  {
                    PlatformAdvantage.map((ad, index) => {
                      return (
                        <Flex key={index} justifyContent={lang === 'zh-CN' ? 'center' : ''}
                              w="100%" mb="5rem">
                          {
                            lang === 'zh-CN'
                              ?
                              <>
                                <Text fontSize="1.725rem" alignSelf="center" fontWeight="600">
                                  {t(ad.advantage)}
                                </Text>
                                <Text ml="0.5rem" mr="0.5rem" bgColor={ad.color} w="8px" flexShrink={0}></Text>
                              </>
                              :
                              <>
                                <Flex w="30%" alignItems="center" justifyContent="center">
                                  <Text fontSize="1.725rem" alignSelf="center" fontWeight="600" textAlign="center">
                                    {t(ad.advantage)}
                                  </Text>
                                </Flex>
                                <Text ml="0.5rem" mr="0.5rem" bgColor={ad.color} w="8px" flexShrink={0}></Text>
                              </>
                          }
                          <Flex w="66%" flexDirection="column" p="1rem" fontSize="1.125rem">
                            {ad.detail.map((d, index) => {
                              return <Text key={index}>{t(d)}</Text>;
                            })}
                          </Flex>
                        </Flex>
                      );
                    })
                  }
                </>
                :
                <>
                  {
                    PlatformAdvantage.map((ad, index) => {
                      return (
                        <Flex key={index} w="33%" mb="5rem">
                          <Text fontSize="1.725rem" alignSelf="center" fontWeight="600">
                            {t(ad.advantage)}
                          </Text>
                          <Text ml="0.5rem" mr="0.5rem" bgColor={ad.color} w="8px" flexShrink={0}></Text>
                          <Flex flexDirection="column" p="1rem" fontSize="1.125rem">
                            {ad.detail.map((d, index) => {
                              return <Text key={index}>{t(d)}</Text>;
                            })}
                          </Flex>
                        </Flex>
                      );
                    })
                  }
                </>
            }
          </Flex>
        </Box>
      </PlatformAdvantageWrap>
    </Box>
  );
};

export default Platform;
