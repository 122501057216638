import { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";

// Helmet
ReactDOM.render(
  <BrowserRouter>
    {/* 使用了路由懒加载，所以需要使用<Suspense>包起来 */}
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route
          path="/"
          render={(routerProps) => {
            return <App {...routerProps} />;
          }}
        />
      </Switch>
    </Suspense>
  </BrowserRouter>,
  document.getElementById("root")
);
