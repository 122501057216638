import {usePageSize} from "@/App";
import {ImageUrls} from "@/asset/icon";
import {DiagnosisAdvantage} from "@/views/product/config";
import {Box, Flex, Img, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import i18n from "../../react-i18next-config";

const ProductJ: React.FC = () => {
  const pageSize = usePageSize();

  const small = pageSize === "xs"

  const {t} = useTranslation()
  const lang = i18n.language

  const scenariosList = [
    {
      icon: "nb_1",
      text_1: "product.diagnosisPages.scenarios.list.nb_1.text_1",
      text_2: "product.diagnosisPages.scenarios.list.nb_1.text_2",

    },
    {
      icon: "nb_2",
      text_1: "product.diagnosisPages.scenarios.list.nb_2.text_1",
      text_2: "product.diagnosisPages.scenarios.list.nb_2.text_2",
    },
    {
      icon: "nb_3",
      text_1: "product.diagnosisPages.scenarios.list.nb_3.text_1",
      text_2: "product.diagnosisPages.scenarios.list.nb_3.text_2",
    }
  ]

  return (
    <Box className={`ProductJ`}>
      <Box bgColor="#f8f9fa" position="relative">
        <Box className="wrap">
          <Img
            src={ImageUrls.p_j}
            maxW="616px"
            w="100%"
            position="absolute"
            top={lang === 'zh-CN' ? "-1.25rem" : "2.25rem"}
            right="0"
            className="product-thumb"
          />
          <Box/>
          <Box p="3rem 0" h={lang === 'en-US' ? ' ' : '372px'} className="info">
            <Text fontSize="1.5rem" fontWeight="700" color="#333" mb="0.5rem" lineHeight="2rem">
              {t('product.diagnosisPages.title')}
            </Text>

            <Text fontSize="1.125rem" color="#666" maxW="482px" letterSpacing="0.25px" lineHeight="2rem">
              {t('product.diagnosisPages.desc.line_1')}
              <br/>
              {t('product.diagnosisPages.desc.line_2')}
              <sup>®</sup>
              {t('product.diagnosisPages.desc.line_3')}
              <sup>®</sup>
              {t('product.diagnosisPages.desc.line_4')}
              <br/>
              {
                lang === 'zh-CN'
                  ?
                  <>
                    {t('product.diagnosisPages.desc.line_5')}
                  </>
                  : <></>
              }
            </Text>
          </Box>
        </Box>
      </Box>

      <Box mt="6rem" pb="6rem" background={`url(${ImageUrls.c_bg})`} bgSize="cover">
        <Box className="wrap">
          <Box m="auto" pb="3rem" pt="3rem" color="white">
            <Text fontSize="1.625rem" fontWeight="600">
              {t('product.diagnosisPages.value.title')}
            </Text>
          </Box>
          <Flex justifyContent="space-around" flexWrap="wrap">
            {[
              {icon: "nc_1", text: "product.diagnosisPages.value.nc_1"},
              {icon: "nc_2", text: "product.diagnosisPages.value.nc_2"},
              {icon: "nc_3", text: "product.diagnosisPages.value.nc_3"},
              {icon: "nc_4", text: "product.diagnosisPages.value.nc_4"},
            ].map((it) => (
              // <Box key={it.icon} w={small ? "100%" : "auto"} m={small ? "0 0 3rem 6.5rem" : "auto"}>
              <Box key={it.icon} w={small ? "100%" : lang === 'en-US' ? "40%" : "auto"}
                   m={small ? "0 0 3rem 6.5rem" : lang === 'en-US' ? "16px 0 0 0 " : "auto"}>
                <Img src={ImageUrls[it.icon]} mb="1rem"/>
                <Text fontSize="1.125rem" color="#fff" lineHeight="1.75rem">
                  {t(it.text)}
                </Text>
              </Box>
            ))}
          </Flex>
        </Box>
      </Box>

      <Box className="wrap advantages" pt="5rem" bgColor="white">
        <Box mb="3rem" fontWeight="#333" textAlign="center">
          <Text fontSize="1.625rem" fontWeight="bold">
            {t("product.diagnosisPages.advantages.title")}
          </Text>
        </Box>

        <Flex justify="center" flexWrap="wrap">
          {DiagnosisAdvantage.map((item) => {
            return (
              <Box w={small ? "100%" : "33%"} key={item.icon} position="relative" p="0.5rem">
                <Box p="1.25rem" h="100%" boxShadow="5px 8px 36px 0px #0000000D">
                  <Flex justifyContent="space-between" mb="0.5rem" alignItems="center">
                    <Img src={ImageUrls[item.index]}/>
                    <Img src={ImageUrls[item.icon]} w="25%"/>
                  </Flex>

                  <Text fontSize="1.125rem" color="#000" lineHeight="1.75rem" fontWeight="bold">
                    {t(item.title)}
                  </Text>
                  <Text fontSize="1.125rem" color="#030622" lineHeight="1.75rem">
                    {t(item.detail)}
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Flex>
      </Box>

      {/*患者故事*/}
      <Box mt="5rem" pb="6rem" background={`url(${ImageUrls.s_bg})`} bgSize="cover">
        <Box maxW="1140px" m="auto" w="100%">
          <Box maxW="1140px" m="auto" pb="3rem" pt="3rem" color="white" textAlign="center">
            <Text fontSize="1.625rem" fontWeight="600">
              {t('product.diagnosisPages.atories.title')}
            </Text>
          </Box>

          <Box position="relative" mb="4rem">
            <Flex justifyContent="space-between" alignItems="flex-start">
              <Flex alignItems="center">
                <Img src={ImageUrls.avatar_1} w="5rem" h="5rem" mr="1rem"/>
                <Text fontSize="1.125rem" color="#fff" lineHeight="1.75rem">
                  {t('product.diagnosisPages.atories.example.case_1.title')}
                  <br/>
                  {t('product.diagnosisPages.jietaisi')}
                  <sup>®</sup>
                  {t('product.diagnosisPages.atories.example.case_1.desc')}
                </Text>
              </Flex>
              <Box w="50%" bg="white" p="2rem" lineHeight="2rem" color="#666" fontSize="1.125rem">
                {t('product.diagnosisPages.atories.example.case_1.line_1')}
                <sup>®</sup>
                {t('product.diagnosisPages.atories.example.case_1.line_2')}
                {
                  lang === 'zh-CN'
                    ?
                    <>
                      <sup>®</sup>
                      {t('product.diagnosisPages.atories.example.case_1.line_3')}
                    </>
                    : <></>
                }
              </Box>
            </Flex>

            <Box position="relative" w="50%" transform="transLate(8rem, -0.5rem)">
              <Box position="absolute" bgColor="#4878f0" w="100%" height="100%" top="-1rem" left="-1rem"></Box>
              <Text
                fontSize="1.125rem"
                p="2rem"
                bgColor="#fff"
                lineHeight="1.75rem"
                zIndex="2"
                position="relative"
                color="#666666"
              >
                {t('product.diagnosisPages.atories.example.case_1.explain.line_1')}
                <sup>®</sup>
                {t('product.diagnosisPages.atories.example.case_1.explain.line_2')}
                <sup>®</sup>
                {t('product.diagnosisPages.atories.example.case_1.explain.line_3')}
              </Text>
            </Box>
          </Box>

          <Box position="relative">
            <Flex justifyContent="space-between" alignItems="center">
              <Box w="50%" bg="white" p="1rem" lineHeight="2rem" color="#666" fontSize="1.125rem">
                <Text
                  fontSize="1.125rem"
                  p="1rem"
                  bgColor="#fff"
                  lineHeight="1.75rem"
                  zIndex="2"
                  position="relative"
                  color="#666"
                >
                  {t('product.diagnosisPages.atories.example.case_1.explain.line_4')}
                  <sup>®</sup>
                  {t('product.diagnosisPages.atories.example.case_1.explain.line_5')}
                </Text>
              </Box>

              <Flex alignItems="center">
                <Img src={ImageUrls.avatar_2} w="5rem" h="5rem" mr="1rem"/>
                <Text fontSize="1.125rem" color="#fff" lineHeight="1.75rem">
                  {t('product.diagnosisPages.atories.example.case_2.title')}
                  <br/>
                  {t('product.diagnosisPages.jietaisi')}
                  <sup>®</sup>
                  {t('product.diagnosisPages.atories.example.case_2.desc')}
                </Text>
              </Flex>
            </Flex>

            <Box position="relative" w="50%" transform="transLate(80%, -0.5rem)" zIndex="9">
              <Box position="absolute" bgColor="#4878f0" w="100%" height="100%" top="-1rem" left="-1rem"></Box>
              <Box
                p="2rem"
                lineHeight="2rem"
                bg="white"
                color="#666"
                fontSize="1.125rem"
                zIndex="1"
                position="relative"
              >
                {t('product.diagnosisPages.atories.example.case_2.line_1')}
                <br/>
                {t('product.diagnosisPages.atories.example.case_2.line_2')}
                <sup>®</sup>
                {t('product.diagnosisPages.atories.example.case_2.line_3')}
                <br/>
                <Flex justify="space-between">
                  <Box w="48%">
                    <Text>
                      {t('product.diagnosisPages.atories.example.case_2.record.item_1.date')}
                    </Text>
                    <Text>
                      {t('product.diagnosisPages.atories.example.case_2.record.item_1.result')}
                    </Text>
                  </Box>
                  <Box w="48%">
                    <Text>
                      {t('product.diagnosisPages.atories.example.case_2.record.item_2.date')}
                    </Text>
                    <Text w={lang === 'en-US' ? '100%' : ''}>
                      {t('product.diagnosisPages.atories.example.case_2.record.item_2.sub')}
                      <br/>
                      {t('product.diagnosisPages.atories.example.case_2.record.item_2.line_1')}
                      <br/>
                      {t('product.diagnosisPages.atories.example.case_2.record.item_2.line_2')}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/*应用场景*/}
      <Box position="relative" pt="4rem" pb="6rem" bg="#f4f6f8">
        <Box textAlign="center" pb="2rem">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('product.diagnosisPages.scenarios.title')}
          </Text>
        </Box>

        <Box className="wrap">
          <Flex justify="space-between" flexWrap="wrap">
            {scenariosList.map((it) => (
              <Box w={small ? "100%" : "33.333%"} key={it.icon} mb={small ? "1.25rem" : 0}>
                <Box
                  p="2rem 1.75rem 1rem"
                  bgColor="white"
                  m="1rem"
                  height="100%"
                  boxShadow="0px 0px 30px 8px #00000014"
                >
                  <Img src={ImageUrls[it.icon]} h="24px" mb="1rem"/>
                  <Text fontSize="1.125rem" color="#999" lineHeight="2rem">
                    {/*<Box>*/}
                    {t(it.text_1)}
                    <sup>®</sup>
                    {t(it.text_2)}
                    {/*</Box>*/}
                  </Text>
                </Box>
              </Box>
            ))}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductJ;
