import styled from "styled-components";

export const NavWrap = styled.div`
  background: white;
  box-shadow: 0 10px 6px 0 #d9d9d926;
  position: relative;
  z-index: 999;

  .link {
    border: none;
    text-decoration: none;

    &:hover {
      border: none;
      text-decoration: none;
    }

    &:active {
      border: none;
      text-decoration: none;
    }

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &.active {
      background: #4878f0;
      color: #fff;
    }
  }

  .CfQPL {
    position: relative;
    display: inline-block;
    height: 100%;
    font-size: 18px;
    margin-top: 0;
    margin-inline: 32px 0;
    margin-bottom: 0;
  }
  .mobile-lang{
    position: relative;
    display: inline-block;
    //height: 100%;
    font-size: 18px;
    //margin-top: 0;
    //margin-bottom: 0;
  }

  .kcPPls {
    height: 100%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    &.active {
      background-color: rgb(72, 120, 240);
      color: rgb(255, 255, 255);
    }
  }

  .kcPPls svg {
    margin: 0 5px;
  }

  .hover {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(255, 255, 255);
    color: black;
  }

  .hover div{
    padding: 20px;
    cursor: pointer;
    white-space: nowrap;
  }

  .hover div:hover {
    background-color: rgb(72, 120, 240);
    color: rgb(255, 255, 255);
  }

  .hover div:hover .kcPPls {
    pointer-events: none;
  }

  .hover-mobile{
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(255, 255, 255);
    color: black;
  }

  .hover-mobile div:hover {
    background-color: rgb(72, 120, 240);
    color: rgb(255, 255, 255);
  }

  .hover-mobile div{
    padding: 5px;
    cursor: pointer;
    white-space: nowrap;
  }
`;
