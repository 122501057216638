import {usePageSize} from "@/App";
import {ImageUrls} from "@/asset/icon";
import {service} from "@/service/request";
import {EnvironmentOutlined, MailOutlined, PhoneOutlined} from "@ant-design/icons";
import {Box, Flex, Image, Text} from "@chakra-ui/react";
import {Button, Col, Form, Input, message, Modal, Row, Select} from "antd";
import {useEffect, useState} from "react";
import {useLocation} from "react-router";
import "./style.css";
import {useTranslation} from "react-i18next";
import ct_01_en from '../../asset/image/english/ct_01_en.png'
import i18n from "../../react-i18next-config";

const {Option} = Select;

const Contact: React.FC = () => {
  const [, setKey] = useState<string>("platform");
  const {search} = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {t} = useTranslation()
  const lang = i18n.language

  const Cooperate = [
    {
      icon: "ch_i_01",
      title: t('contact.cooperate.01.title'),
      detail: [
        t('contact.cooperate.01.details.line_1'),
        t('contact.cooperate.01.details.line_2'),
        t('contact.cooperate.01.details.line_3')
      ],
    },
    {
      icon: "ch_i_02",
      title: t("contact.cooperate.02.title"),
      detail: [
        <span>
          GlyFace
          <sup>®</sup>
          {t("contact.cooperate.02.details.line_1")}
        </span>,
        <span>
          GlySeer
          <sup>®</sup>
          {t("contact.cooperate.02.details.line_2")}
        </span>,
      ],
    },
    {
      icon: "ch_i_03",
      title: t("contact.cooperate.03.title"),
      detail: [
        t("contact.cooperate.03.details.line_1"),
        t("contact.cooperate.03.details.line_2"),
      ],
    },
    {
      icon: "ch_i_04",
      title: t("contact.cooperate.04.title"),
      detail: [
        t("contact.cooperate.04.details.line_1"),
        t("contact.cooperate.04.details.line_2"),
      ],
    },
  ];

  useEffect(() => {
    if (search) {
      const t = search?.split("=")[1];
      setKey(t);
    }
  }, [search]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values: any) => {
    const res = await service({
      method: "POST",
      url: "api/applications",
      data: {data: values},
    });
    if (res) {
      message.success(t('contact.success'));
    }
    setIsModalOpen(false);
  };

  const pageSize = usePageSize();
  const small = pageSize === "xs";
  return (
    <Box className="Content">
      <Box className="banner">
        <Image src={ImageUrls.ct_bg} htmlWidth="100%" className="product-nav"/>
      </Box>

      <Box textAlign="center" pb={small ? "" : "4rem"} pt="6rem">
        <Box mb="3rem">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('contact.title')}
          </Text>
        </Box>

        <Text color="#4878F0" fontSize="1.75rem" mb="3rem" fontWeight="600">
          {t('contact.global')}
        </Text>

        <Box className="wrap" mt="2rem">
          <Flex justifyContent="space-between" pb="1rem" flexWrap="wrap">
            {Cooperate.map((item) => {
              return (
                <Flex key={item.icon} color="#4878f0" w={small ? "100%" : "25%"} textAlign="center">
                  <Box
                    m="0.375rem"
                    bg="white"
                    w="100%"
                    p="1.5rem 1.5rem 2rem"
                    borderRadius="4px"
                    boxShadow="0 0 10px #00000012"
                  >
                    <Image w="150px" src={ImageUrls[item.icon]} display="inline-block" p="1.5rem"/>
                    <Text fontWeight="600" fontSize="1.75rem" mb="1.5rem">
                      {item.title}
                    </Text>
                    {item.detail.map((t, index) => {
                      return (
                        <Text textAlign="left" color="#999999a0" fontSize="1.275rem" key={index}>
                          {t}
                        </Text>
                      );
                    })}
                  </Box>
                </Flex>
              );
            })}
          </Flex>
        </Box>
        <Box textAlign="center" mt="2.5rem">
          <Text
            bgColor="#4878F0"
            fontSize="1.5rem"
            mb="3rem"
            cursor="pointer"
            borderRadius="0.5rem"
            display="inline-block"
            color="#fff"
            padding="1rem 4rem"
            boxShadow="0 2px 1rem #4878F090"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            {t('contact.apply.title')}
          </Text>
        </Box>
      </Box>

      <Modal
        width="720px"
        title={t('contact.apply.from.subTitle')}
        onOk={handleOk}
        onCancel={handleCancel}
        visible={isModalOpen}
        footer={null}
        wrapClassName="contact-modal"
        destroyOnClose={true}
      >
        <Form
          colon={false}
          name="normal_login"
          className="login-form"
          initialValues={{remember: true}}
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={t('contact.apply.from.province.label')} name="province"
                         rules={[{required: true, message: t('contact.apply.from.province.enter')}]}>
                <Input placeholder={t('contact.apply.from.province.enter')}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span>{t('contact.apply.from.business.current')}{small ? "" :
                  <br/>}{t('contact.apply.from.business.label')}</span>}
                labelCol={{flex: small ? "200px" : "none"}}
                name="main_business"
                rules={[{required: true, message: t('contact.apply.from.business.enter')}]}
              >
                <Input placeholder={t('contact.apply.from.business.enter')}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={t('contact.apply.from.company.label')} name="company_name"
                         rules={[{required: true, message: t('contact.apply.from.company.enter')}]}>
                <Input placeholder={t('contact.apply.from.company.enter')}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('contact.apply.from.prefer.label')}
                name="prefer_business"
                rules={[{required: true, message: t('contact.apply.from.prefer.enter')}]}
              >
                <Input placeholder={t('contact.apply.from.prefer.enter')}/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={t('contact.apply.from.contactor.label')} name="contactor"
                         rules={[{required: true, message: t('contact.apply.from.contactor.enter')}]}>
                <Input placeholder={t('contact.apply.from.contactor.enter')}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('contact.apply.from.product.label')} name="prefer_product"
                         rules={[{required: true, message: t('contact.apply.from.product.enter')}]}>
                <Select placeholder={t('contact.apply.from.product.enter')}>
                  <Option value="洁太司">
                    {t('contact.apply.from.jietaisi')}
                    <sup>®</sup>
                  </Option>

                  <Option value="甘达健">
                    {t('contact.apply.from.gandajian')}
                    <sup>®</sup>
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={t('contact.apply.from.position.label')} name="position"
                         rules={[{required: true, message: t('contact.apply.from.position.enter')}]}>
                <Input placeholder={t('contact.apply.from.position.enter')}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span>{t('contact.apply.from.other.label')}{small ? "" :
                  <br/>}{t('contact.apply.from.other.share')}</span>}
                name="other_information"
                rules={[{required: true, message: t('contact.apply.from.other.enter')}]}
                labelCol={{flex: small ? "200px" : "none"}}
              >
                <Input placeholder={t('contact.apply.from.other.enter')}/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={t('contact.apply.from.mobile.label')} name="mobile"
                         rules={[{required: true, message: t('contact.apply.from.mobile.enter')}]}>
                <Input placeholder={t('contact.apply.from.mobile.enter')}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('contact.apply.from.address.label')} name="address"
                         rules={[{required: true, message: t('contact.apply.from.address.enter')}]}>
                <Input placeholder={t('contact.apply.from.address.enter')}/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={<span>{t('contact.apply.from.revenue.label')}{small ? "" :
                  <br/>}{t('contact.apply.from.revenue.quota')}</span>}
                name="revenue"
                rules={[{required: true, message: t('contact.apply.from.revenue.enter')}]}
                labelCol={{flex: small ? "200px" : "none"}}
              >
                <Input placeholder={t('contact.apply.from.revenue.enter')}/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              {t('contact.apply.from.submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Box textAlign="center" pb="3rem">
        <Box mb="3rem">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('contact.layout')}
          </Text>
        </Box>

        {
          lang === 'en-US'
            ?
            <>
              <Image src={ct_01_en} h="auto" m="auto"/>
            </>
            :
            <>
              <Image src={ImageUrls.ct_01} h="auto" m="auto"/>
            </>
        }
      </Box>

      <Box className="wrap" p={small ? "0rem 1.5rem" : "3rem 1.5rem"}>
        <Box mb="3rem" textAlign="center">
          <Text color="#414040" fontSize="2rem" fontWeight="600">
            {t('contact.method')}
          </Text>
        </Box>
        <Flex flexWrap="wrap" justifyContent="center" alignItems="flex-start" mb="2rem">
          <Flex w={small ? "100%" : "33%"} mb="1rem" flexDirection="column" color="white" className="item">
            <Image src={ImageUrls.ct_02} h="auto"/>
            <Box h={lang === 'zh-CN' ? small ? "120px" : "148px" : small ? "120px" : "188px"} bgColor="#4878f0aa"
                 p="1rem 2rem" pb="1rem"
                 className="text-content" w="100%" bottom="0">
              <Text fontSize="1.75rem" alignSelf="center" fontWeight="600" mb="0.5rem">
                {t('contact.01.sub')}
              </Text>
              <Flex fontSize="1.25rem" alignItems="center" lineHeight="1">
                <PhoneOutlined/>
                <Text ml="0.5rem" lineHeight="1.25">
                  {t('contact.01.phone')}
                  <br/>
                  025-85209692
                  <br/>
                  {t('contact.01.issue')}
                </Text>
              </Flex>
            </Box>
          </Flex>

          {small ? (
            <Flex w={"100%"} mb="1rem" flexDirection="column" color="#414040" className="item">
              <Image src={ImageUrls.ct_03} h="auto"/>
              <Box h="110px" bgColor="#efefefaa"
                   p="1rem 2rem"
                   pb="1rem" className="text-content" w="100%" bottom="0">
                <Text fontSize="1.75rem" alignSelf="center" fontWeight="600" mb="0.5rem">
                  {t('contact.02.sub')}
                </Text>
                <Flex fontSize="1.25rem" alignItems="center" lineHeight="1">
                  <MailOutlined/>
                  <Text ml="0.5rem" lineHeight="1.25">
                    {t('contact.02.email')}
                    <br/>
                    marketing@sdbiomed.com
                  </Text>
                </Flex>
              </Box>
            </Flex>
          ) : (
            <Flex w={"33%"} mb="1rem" flexDirection="column" color="#414040" className="item">
              <Box h={lang === 'zh-CN' ? "148px" : "188px"}
                   bgColor="#efefefaa" p="1rem 2rem"
                   pb="1rem" className="text-content" w="100%" top="0">
                <Text fontSize="1.625rem" alignSelf="center" fontWeight="600" mb="0.5rem">
                  {t('contact.02.sub')}
                </Text>
                <Flex fontSize="1.25rem" alignItems="center" lineHeight="1">
                  <MailOutlined/>
                  <Text ml="0.5rem" lineHeight="1.25">
                    {t('contact.02.email')}
                    <br/>
                    marketing@sdbiomed.com
                  </Text>
                </Flex>
              </Box>
              <Image src={ImageUrls.ct_03} h="auto"/>
            </Flex>
          )}

          <Flex w={small ? "100%" : "33%"} mb="1rem" flexDirection="column" color="#fff" className="item">
            <Image src={ImageUrls.ct_04} h="auto"/>
            <Box h={lang === 'zh-CN' ? small ? "140px" : "148px" : small ? "140px" : "188px"} bgColor="#1a1a1faa"
                 p="1rem 2rem" pb="1rem"
                 className="text-content" w="100%" bottom="0">
              <Text fontSize="1.75rem" alignSelf="center" fontWeight="600" mb="0.5rem">
                {t('contact.03.sub')}
              </Text>
              <Flex fontSize="1.25rem" alignItems="center" lineHeight="1">
                <EnvironmentOutlined/>
                <Text ml="0.5rem" lineHeight="1.25">
                  {t('contact.03.address')}
                  <br/>
                  {t('contact.03.line_1')}
                  <br/>
                  {t('contact.03.line_2')}
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Contact;
