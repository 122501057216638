import { usePageSize } from "@/App";
import { ImageUrls } from "@/asset/icon";
import Diagnosis from "@/views/product/diagnosis";
import Estimate from "@/views/product/estimate";
import Platform from "@/views/product/platform";
import { ProductNav } from "@/views/product/styled";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./style.css";
import {useTranslation} from "react-i18next";

const Product: React.FC = () => {
  const [key, setKey] = useState<string>("platform");
  const { search } = useLocation();
  const history = useHistory();

  const {t} = useTranslation()

  const NavList = [
    {
      title: (
        <Text>
          { t('product.platform.part_1') }
          <sup>®</sup>
          &nbsp;{ t('product.platform.part_2') }
        </Text>
      ),
      key: "platform",
    },
    { title: <Text>{ t('product.diagnosis') }</Text>, key: "diagnosis" },
    { title: <Text>{ t('product.estimate') }</Text>, key: "estimate" },
  ];

  useEffect(() => {
    if (search) {
      const t = search?.split("=")[1];
      setKey(t);
    }
  }, [search]);

  const pageSize = usePageSize();
  return (
    <Box className={`${pageSize} Product`}>
      <Box className="banner">
        <Image src={ImageUrls.product_banner} htmlWidth="100%" className="product-nav" />
      </Box>
      <ProductNav>
        <Flex justify="space-between">
          {NavList.map((nav) => (
            <Flex
              className={`item ${key === nav.key ? "active" : ""}`}
              key={nav.key}
              justifyContent="center"
              alignItems="center"
              onClick={() => {
                history.push(`/product?tab=${nav.key}`);
              }}
            >
              {nav.title}
            </Flex>
          ))}
        </Flex>
      </ProductNav>
      <Box className="main">
        {key === "platform" && <Platform />}
        {key === "diagnosis" && <Diagnosis />}
        {key === "estimate" && <Estimate />}
      </Box>
    </Box>
  );
};

export default Product;
