import { BaseUrl } from "@/constant";
import { message } from "antd";
import axios, { AxiosRequestConfig } from "axios";

export const BASE_URL = process.env.NODE_ENV === "production" ? `${BaseUrl}/` : "http://localhost:3000/";
// export const BASE_URL = BaseUrl

interface Config extends AxiosRequestConfig {
  crossDomain?: boolean;
}
const requestConfig: Config = {
  baseURL: BASE_URL, // api base_url
  timeout: 60000, // 请求超时时间
  withCredentials: true,
  responseType: "json",
};

// 创建 axios 实例
const service = axios.create(requestConfig);

// loading对象
// let loading: any;
// 当前正在请求的数量
// let needLoadingRequestCount: number = 0;

// 显示loading
// function showLoading(target: string) {
//   // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
//   // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
//   if (needLoadingRequestCount === 0 && !loading) {
//     loading = Loading.service({
//       lock: true,
//       text: "Loading...",
//       background: "rgba(255, 255, 255, 0.5)",
//       target: target || "body",
//     });
//   }
//   needLoadingRequestCount++;
// }

// 隐藏loading
// function hideLoading() {
//   needLoadingRequestCount--;
//   needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); // 做个保护
//   if (needLoadingRequestCount === 0) {
//     // 关闭loading
//     toHideLoading();
//   }
// }

// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
// const toHideLoading = _.debounce(() => {
//   if (loading != null) {
//     loading.close();
//   }
//   loading = null;
// }, 300);

const err = (error: any) => {
  // if (error.config && error.config.headers.showLoading !== false) {
  //   hideLoading();
  // }

  if (error.response) {
    if (error.response.status === 400) {
      console.log(400);
    } else if (error.response.status === 403) {
      console.log(403);
    } else {
      const data = error.response.data;
      if (data && data.message) {
        message.error(data.message || "Error");
      } else {
        message.error("Error");
      }
      return Promise.reject(error);
    }
  }
};

// request interceptor
service.interceptors.request.use((config: AxiosRequestConfig) => {
  console.log("🚀 ~ file: request.ts:82 ~ config:", config);
  // 判断当前请求是否设置了不显示Loading
  // if (config.headers.showLoading !== "false") {
  //   showLoading(config.headers.loadingTarget);
  // }
  return config;
}, err);

// response interceptor
service.interceptors.response.use((response) => {
  // 判断当前请求是否设置了不显示Loading（不显示自然无需隐藏）
  // if (response.config.headers.showLoading !== "false") {
  //   hideLoading();
  // }
  console.log("🚀 ~ file: request.ts:98 ~ response:", response);
  return response.data;
}, err);

// showLoading, hideLoading

export { service };
